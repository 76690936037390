import { template } from "@ember/template-compiler";
import { fn, get } from '@ember/helper';
import { array } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { add } from 'ember-math-helpers/helpers/add';
import PhAirplaneTakeOff from 'ember-phosphor-icons/components/ph-airplane-takeoff';
import PhCalendar from 'ember-phosphor-icons/components/ph-calendar';
import PhStudent from 'ember-phosphor-icons/components/ph-student';
import PhUser from 'ember-phosphor-icons/components/ph-user';
import Route from 'ember-route-template';
import Tooltip from 'vfc-client/components/ui/tooltip';
import config from 'vfc-client/config/environment';
import type { Trip } from 'vfc-client/types/trip';
import { diffDate } from 'vfc-client/utils/date-utils';
import { formatDate } from 'vfc-client/utils/date-utils';
interface MyTripsSignature {
    Element: HTMLDivElement;
    Args: {
        myTrips: Array<Trip>;
    };
}
let MyTrips = class MyTrips extends Component<MyTripsSignature> {
    @service
    router: RouterService;
    @action
    navigateToTripDetails(destinationId1: number) {
        this.router.transitionTo('authenticated.trip-details', destinationId1, {
            queryParams: {
                tab: 'trip-details'
            }
        });
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section class="w-full px-8 py-8">
      <div class="py-8">
        <div class="text-lg font-semibold pb-4">
          {{t "page.landing-page.my-trips.title"}}
        </div>

        <div class="grid grid-cols-landingPageCards gap-4">
          {{!
          Example https://findecursocolegio.com/viajes-fin-de-curso/calculadora?country=16&travel_id=36&students=20&teachers=2&start_date=2024-01-30&end_date=2024-02-06
          }}
          {{#each @myTrips as |trip|}}
            <a class="cursor-pointer" {{on "click" (fn this.navigateToTripDetails trip.id)}}>
              <div class="rounded-xl overflow-hidden border border-neutral-200 hover:shadow-lg">
                <div class="w-full flex align-items-center h-[150px] bg-neutral-200">
                  {{#if trip.image}}
                    <img class="w-full h-full object-cover" src="{{trip.image}}" />
                  {{else}}
                    <PhAirplaneTakeOff class="w-6 h-6 flex-shrink-0" style="margin:auto" />
                  {{/if}}
                </div>
                <div class="px-6 py-4 bg-white">
                  <div class="font-bold text-xl mb-2">
                    {{get trip "destination.name"}}
                  </div>
                  <div class="flex items-center gap-2">
                    <PhCalendar />
                    <span class="capitalize">
                      {{formatDate trip.checkIn config.APP.DATE.USER_FRIENDLY_FORMAT}}
                    </span>
                  </div>
                  <Tooltip @showArrow={{true}} @inline={{true}}>
                    <:tooltipable as |p|>
                      <div class="flex items-center gap-2" {{p.hook}}>
                        <PhUser />
                        <span>
                          {{t "page.landing-page.my-trips.card-info.passengers"}}
                          {{trip.totalStudents}}
                          +
                          {{trip.totalTeachers}}
                        </span>
                      </div>
                    </:tooltipable>
                    <:content>
                      <div class="flex items-center gap-2">
                        <PhStudent />
                        <span>
                          {{t "page.landing-page.my-trips.card-info.totalStudents"}}:
                          {{trip.totalStudents}}
                        </span>
                      </div>
                      <div class="flex items-center gap-2">
                        <PhUser />
                        <span>
                          {{t "page.landing-page.my-trips.card-info.totalTeachers"}}:
                          {{trip.totalTeachers}}
                        </span>
                      </div>
                    </:content>
                  </Tooltip>
                  <div class="pt-4 text-sm">
                    {{#let (diffDate trip.checkOut trip.checkIn "day") as |days|}}
                      {{t
                        "page.landing-page.my-trips.card-info.days-and-nights"
                        d=(add (array days 1))
                        n=days
                      }}
                    {{/let}}
                  </div>
                </div>
              </div>
            </a>
          {{/each}}
        </div>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default Route<{
    model: {
        myTrips: Array<Trip>;
    };
}>(template(`
    <div class="w-full bg-neutral-50">
      <main class="m-auto">
        <MyTrips @myTrips={{@model.myTrips}} />
      </main>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
