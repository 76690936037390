import './assets/app.css';
import '@fontsource/dm-sans';
import 'dayjs/locale/es';

import Application from '@ember/application';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import config from 'vfc-client/config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

dayjs.locale('es');
dayjs.extend(relativeTimePlugin);

loadInitializers(App, config.modulePrefix);
