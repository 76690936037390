import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import config from 'vfc-client/config/environment';

export default class TokenAuthenticator extends BaseAuthenticator {
  async authenticate(email: string, password: string) {
    const response: Response = await fetch(`${config.APP.API.ADMIN_FINDECURSO_PREFIX}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      return response.json().then(({ token }) => ({ token }));
    } else {
      throw new Error(await response.text());
    }
  }

  async restore(authToken: { token: string }) {
    return authToken;
  }
}
