import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { Loader } from '@googlemaps/js-api-loader';
import { modifier } from 'ember-modifier';
import config from 'vfc-client/config/environment';
interface GoogleMapSignature {
    Element: HTMLElement;
    Args: {
        lng: number;
        lat: number;
        zoom: number;
    };
}
export default class GoogleMap extends Component<GoogleMapSignature> {
    map: unknown;
    mapElement: HTMLElement;
    get zoom() {
        return this.args.zoom ?? 8;
    }
    onInit = modifier(async (element1: HTMLElement)=>{
        this.mapElement = element1;
        const loader1 = new Loader({
            apiKey: config.APP.GOOGLE_MAPS_API_KEY,
            version: 'weekly',
            libraries: [
                'marker'
            ]
        });
        try {
            await loader1.load();
            this.initMap();
        } catch (error1) {
            console.error(error1);
        }
    });
    initMap() {
        const google1 = window.google;
        this.map = new google1.maps.Map(this.mapElement, {
            center: {
                lat: this.args.lat,
                lng: this.args.lng
            },
            zoom: this.zoom,
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            mapId: 'yours-here'
        });
        const map1 = this.map;
        new google1.maps.marker.AdvancedMarkerElement({
            position: {
                lat: this.args.lat,
                lng: this.args.lng
            },
            map: map1
        });
    }
    static{
        template(`
    <div ...attributes {{this.onInit}}></div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
