import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type UserService from 'vfc-client/services/user-service';

export default class TripDetails extends Route {
  queryParams = {
    tab: { refreshModel: false },
  };

  validTabParams = ['trip-details', 'passengers', 'payments', 'rooms', 'documents'];

  @service declare userService: UserService;
  @service declare router: RouterService;

  beforeModel(transition: Transition) {
    const tabParam = transition.to?.queryParams['tab'] as string;
    const tripId = (transition.to?.params ?? {})['id'];
    if (!tabParam || !this.validTabParams.includes(tabParam)) {
      transition.abort();
      this.router.transitionTo('authenticated.trip-details.index', tripId, {
        queryParams: { tab: 'trip-details' },
      });
    }
  }

  model({ id }: { id: string }, transition: Transition) {
    const activeTab = transition.to?.queryParams['tab'] ?? 'trip-details';

    return {
      id,
      activeTab,
    };
  }
}
