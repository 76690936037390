import { template } from "@ember/template-compiler";
import './passengers-tab.css';
import { concat } from '@ember/helper';
import { fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import type { Args } from '@glimmer/component/-private/component';
import { cached, tracked } from '@glimmer/tracking';
import dayjs from 'dayjs';
import type { FormData } from 'ember-headless-form';
import { HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import PhWarning from 'ember-phosphor-icons/components/ph-warning';
import { PortalTargets } from 'ember-primitives';
import { use } from 'ember-resources';
import { and, eq, not } from 'ember-truth-helpers';
import { debounce } from 'reactiveweb/debounce';
import { TrackedObject } from 'tracked-built-ins';
import { TrackedArray } from 'tracked-built-ins';
import { HeadlessFormWrapper } from 'vfc-client/components/headless-form-wrapper';
import { Accordion } from 'vfc-client/components/ui/accordion';
import Badge from 'vfc-client/components/ui/badge';
import ButtonText from 'vfc-client/components/ui/button/text';
import Loader from 'vfc-client/components/ui/loader';
import Tooltip from 'vfc-client/components/ui/tooltip';
import ResponseError from 'vfc-client/models/response-error';
import Student from 'vfc-client/models/student';
import Teacher from 'vfc-client/models/teacher';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type NotificationService from 'vfc-client/services/notification-service';
import { Notification } from 'vfc-client/services/notification-service';
import type UserService from 'vfc-client/services/user-service';
import type { Trip } from 'vfc-client/types/trip';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';
import { timeFromNow } from 'vfc-client/utils/date-utils';
import { MapperComparator, SortUtils } from 'vfc-client/utils/sort-utils';
import { noSpacesAndLowercase } from 'vfc-client/utils/string-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
import { userInitials } from 'vfc-client/utils/user-utils';
type RestoreItem = {
    index: number | null;
    item: EntityWrapper<Teacher | Student> | null | undefined;
    message?: string;
};
type PassengersResponse = {
    state: string;
    totalStudents: number;
    totalTeachers: number;
    students: Array<Student>;
    teachers: Array<Teacher>;
};
let EntityWrapper = class EntityWrapper<T extends Student | Teacher> {
    status: 'complete' | 'incomplete' | 'nofilled' = 'nofilled';
    fieldsNotCompleted: Array<string> = [];
    entity: T;
    constructor(entity1: T){
        this.entity = entity1;
        this.status = this.calculateStatus(entity1);
    }
    calculateStatus(entity1: T) {
        const skipAttrs1 = [
            'allergy',
            'observations'
        ];
        if (!entity1.id) return 'nofilled';
        Object.getOwnPropertyNames(entity1).forEach((attr1)=>{
            if (!skipAttrs1.includes(attr1) && entity1[attr1] === '') {
                this.fieldsNotCompleted.push(attr1);
            }
        });
        if (this.fieldsNotCompleted.length === 0) {
            return 'complete';
        } else {
            return 'incomplete';
        }
    }
};
/**
 * Passengers tab
 */ interface PassengersTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
        activeTab: string;
    };
    Blocks: {
        default: [];
    };
}
let PassengersTab = class PassengersTab extends Component<PassengersTabSignature> {
    @service
    intl: IntlService;
    @service
    userService: UserService;
    @service
    notificationService: NotificationService;
    formFieldsWithError = new TrackedObject<Record<string, string>>({});
    @use
    authenticatedRemoteData = AuthenticatedRemoteData<PassengersResponse>(()=>urlFor(`/trip/${this.args.trip.id}/passengers`));
    @service('auth/http-client')
    authenticatedHttpClient: AuthenticatedHttpClient;
    students: Array<EntityWrapper<Student>> = new TrackedArray();
    teachers: Array<EntityWrapper<Teacher>> = new TrackedArray();
    @tracked
    activeTab = this.args.activeTab ?? 'students';
    @tracked
    _filterQuery = '';
    inputFilter: HTMLInputElement;
    @tracked
    filterByStatus?: string | null = null;
    @use
    filterQuery = debounce(200, ()=>this._filterQuery);
    @tracked
    errors: Array<unknown> = new TrackedArray();
    constructor(owner1: Owner, args1: Args<PassengersTabSignature>){
        super(owner1, args1);
        this.authenticatedRemoteData.fetchPromise.then((response1)=>{
            Array.from(Array(response1.totalStudents).keys()).map((_value1, index1)=>{
                const student1 = new Student(response1.students[index1]);
                this.students.push(new EntityWrapper(student1));
            });
            Array.from(Array(response1.totalTeachers).keys()).map((_value1, index1)=>{
                const teacher1 = new Teacher(response1.teachers[index1]);
                this.teachers.push(new EntityWrapper(teacher1));
            });
        }).catch((error1: unknown)=>{
            if (error1 instanceof Response) {
                error1.json().then((json1)=>{
                    throw new ResponseError(error1.status, json1);
                });
            } else {
                throw error1;
            }
        });
    }
    anyMatchFilter(listOfAttrs1: Array<string>, compareWith1: string) {
        return listOfAttrs1.some((value1)=>{
            const noSpaceValue1 = noSpacesAndLowercase(value1);
            return value1 && noSpaceValue1.includes(compareWith1);
        });
    }
    strictMatchFilter(listOfAttrs1: Array<string>, compareWith1: string) {
        return listOfAttrs1.some((value1)=>{
            const noSpaceValue1 = noSpacesAndLowercase(value1);
            return value1 && noSpaceValue1 === compareWith1;
        });
    }
    @cached
    get filteredStudents() {
        if (this.isActiveTab('teachers')) return [];
        const students1 = this.students.filter((wrapper1)=>{
            let passfilters1 = true;
            const student1 = wrapper1.entity;
            if (this.filterQuery) {
                const anyMatchFilter1 = this.anyMatchFilter([
                    student1.name,
                    student1.lastName,
                    `${student1.name}${student1.lastName}`,
                    student1.document
                ], this.filterQuery.replace(/\s/g, '').toLowerCase());
                passfilters1 = anyMatchFilter1;
            }
            if (passfilters1 && this.filterByStatus) {
                const statusMatchFilter1 = this.strictMatchFilter([
                    wrapper1.status
                ], this.filterByStatus);
                passfilters1 = statusMatchFilter1;
            }
            return passfilters1;
        });
        SortUtils.sortBy(students1, 'DESC', 'status', MapperComparator({
            nofilled: 2,
            incomplete: 1,
            complete: 0
        }));
        return students1;
    }
    @cached
    get filteredTeachers() {
        if (this.isActiveTab('students')) return [];
        const teachers1 = this.teachers.filter((wrapper1)=>{
            let passfilters1 = true;
            const teacher1 = wrapper1.entity;
            if (this.filterQuery) {
                const anyMatchFilter1 = this.anyMatchFilter([
                    teacher1.name,
                    teacher1.lastName,
                    `${teacher1.name}${teacher1.lastName}`,
                    teacher1.document
                ], this.filterQuery.replace(/\s/g, '').toLowerCase());
                passfilters1 = anyMatchFilter1;
            }
            if (passfilters1 && this.filterByStatus) {
                const statusMatchFilter1 = this.strictMatchFilter([
                    wrapper1.status
                ], this.filterByStatus);
                passfilters1 = statusMatchFilter1;
            }
            return passfilters1;
        });
        return teachers1;
    }
    get totalStudentsCompleted() {
        return this.filteredStudents.reduce((acc1, cv1)=>{
            if (this.isEntityFullyCompleted(cv1)) acc1++;
            return acc1;
        }, 0);
    }
    get totalTeachersCompleted() {
        return this.filteredTeachers.reduce((acc1, cv1)=>{
            if (this.isEntityFullyCompleted(cv1)) acc1++;
            return acc1;
        }, 0);
    }
    isFieldDisabled = (field1: string = '', entity1: Student | Teacher = null)=>{
        if (entity1 && entity1.active === 'false') {
            return true;
        } else if (this.args.trip.modifyClientData === 'all-except-name-and-surname') {
            return field1 === 'name' || field1 === 'lastName';
        } else if (this.args.trip.modifyClientData === 'all') {
            return false;
        }
        return true;
    };
    @action
    setActiveTab(event1: Event) {
        event1.preventDefault();
        const target1 = event1.target;
        if (target1 instanceof HTMLElement) {
            const dataset1 = target1.dataset;
            if ('tab' in dataset1) {
                const activeTab1 = dataset1['tab'] ?? 'students';
                if (activeTab1 === this.activeTab) return;
                this.activeTab = activeTab1;
                sessionStorage.setItem(`${this.userService.username}.Passengers.activeTab`, this.activeTab);
                this._filterQuery = '';
                this.inputFilter.value = '';
            }
        }
    }
    isActiveTab = (tab1: string)=>{
        return tab1 === this.activeTab;
    };
    registerInputFilter = modifier((element1: HTMLInputElement)=>{
        this.inputFilter = element1;
    });
    @action
    setfilterQuery({ target: target1 }: {
        target: HTMLInputElement;
    }) {
        this._filterQuery = target1.value;
    }
    @action
    setfilterByStatus({ target: target1 }: {
        target: HTMLSelectElement;
    }) {
        const [option1] = target1.selectedOptions;
        this.filterByStatus = option1?.value === 'all' ? null : option1?.value;
    }
    hasError = (fieldName1: string)=>{
        return !!this.formFieldsWithError[fieldName1];
    };
    @action
    validateInputNatively(event1: Event) {
        const input1 = event1.target as HTMLInputElement;
        const validityState1 = input1.validity;
        if (validityState1.valueMissing) {
            input1.setCustomValidity(this.intl.t('common.form.validation.valueMissing'));
        } else if (validityState1.patternMismatch) {
            input1.setCustomValidity(this.intl.t('common.form.validation.patternMismatch'));
        } else if (validityState1.tooShort) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooShort'));
        } else if (validityState1.tooLong) {
            input1.setCustomValidity(this.intl.t('common.form.validation.tooLong'));
        } else {
            input1.setCustomValidity('');
        }
    }
    @action
    resetCustomValidity(event1: Event) {
        const input1 = event1.target as HTMLInputElement;
        input1.setCustomValidity('');
    }
    validateExpirationDate = (documentExpirationDate1: string)=>{
        if (documentExpirationDate1 && dayjs(documentExpirationDate1).isBefore(dayjs(this.args.trip.checkOut))) {
            return [
                {
                    type: 'documentExpirationDateInvalid',
                    value: documentExpirationDate1,
                    message: this.intl.t('common.form.validation.custom.documentExpirationDateAfterCheckOut')
                }
            ];
        }
    };
    validatePair = (requiredField1: string)=>{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (fieldValue1: string, _field1: string, data1: Record<string, string>)=>{
            if (isBlank(data1[requiredField1]) && !isBlank(fieldValue1)) {
                return [
                    {
                        type: 'requiredPairInvalid',
                        value: requiredField1,
                        message: this.intl.t('common.form.validation.custom.requiredPair', {
                            requiredField: this.intl.t(`common.form.fields.${requiredField1}`)
                        })
                    }
                ];
            }
        };
    };
    @action
    handleStudentSubmit(data1: FormData<Student>) {
        const _data1 = data1 as unknown as Student;
        const entity1 = new Student(_data1);
        this.#handleSubmit({
            entity: entity1,
            formDataId: _data1.id,
            wrapperList: this.students
        });
    }
    @action
    handleTeacherSubmit(data1: FormData<Teacher>) {
        const _data1 = data1 as unknown as Student;
        const entity1 = new Teacher(data1 as unknown as Teacher);
        this.#handleSubmit({
            entity: entity1,
            formDataId: _data1.id,
            wrapperList: this.teachers
        });
    }
    #handleSubmit({ entity: entity1, formDataId: formDataId1, wrapperList: wrapperList1 }: {
        entity: Student | Teacher;
        formDataId: string | number | undefined;
        wrapperList: Array<EntityWrapper<Teacher | Student>>;
    }) {
        let promise1;
        let restoreItem1: RestoreItem = {
            index: null,
            item: null
        };
        const urlEntityType1 = `${entity1.type}s`;
        const headers1 = new Headers({
            'Content-Type': 'application/json'
        });
        if (entity1.id) {
            let index1 = wrapperList1.findIndex((wrapper1)=>formDataId1 === wrapper1.entity.id);
            restoreItem1 = {
                index: index1,
                item: wrapperList1.at(index1),
                message: 'Ha ocurrido un error al actualizar al estudiante'
            };
            entity1.updatedAt = new Date();
            wrapperList1.splice(index1, 1, new EntityWrapper(entity1));
            promise1 = this.authenticatedHttpClient.PUT({
                url: urlFor(`/trip/${this.args.trip.id}/${urlEntityType1}/${entity1.id}`),
                headers: headers1,
                body: entity1.type === 'Teacher' ? Teacher.toPayload(entity1 as Teacher) : Student.toPayload(entity1 as Student)
            });
        } else {
            let index1 = wrapperList1.findIndex((wp1)=>wp1.entity.temporaryId === entity1.temporaryId);
            restoreItem1 = {
                index: index1,
                item: wrapperList1.at(index1),
                message: 'Ha ocurrido un error al añadir al estudiante'
            };
            entity1.id = entity1.temporaryId;
            wrapperList1.splice(index1, 1, new EntityWrapper(entity1));
            promise1 = this.authenticatedHttpClient.POST<{
                id: string;
            }>({
                url: urlFor(`/trip/${this.args.trip.id}/${urlEntityType1}`),
                headers: headers1,
                body: entity1.type ? Teacher.toPayload(entity1 as Teacher) : Student.toPayload(entity1 as Student)
            });
            promise1.then(({ id: id1 }: {
                id: string;
            })=>{
                entity1.id = id1;
                delete entity1['temporaryId'];
            });
        }
        promise1.catch(()=>{
            if (!restoreItem1.index || !restoreItem1.item) return;
            wrapperList1.splice(restoreItem1.index, 1, restoreItem1.item);
            this.notificationService.notify(new Notification({
                type: 'error',
                message: restoreItem1.message ?? ''
            }));
        });
        return promise1;
    }
    /*
   * For any reason I dont know, the textarea (innerHTML) is not properly propagated using ember-headless-form
   * so this mutation observer is a workaround to temporary fix it
   */ setupMutationObserverForTextArea = modifier((element1: HTMLTextAreaElement)=>{
        const observer1 = new MutationObserver((mutations1)=>{
            mutations1.forEach((mutation1)=>{
                if (mutation1.type === 'characterData') {
                    const data1 = (mutation1.target as CharacterData).data;
                    (mutation1.target.parentElement as HTMLTextAreaElement).value = data1;
                }
            });
        });
        // Configuration for the observer
        const config1 = {
            characterData: true,
            attributes: false,
            childList: false,
            subtree: true
        };
        // Start observing the textarea for configured mutations
        observer1.observe(element1, config1);
    });
    patternByDocumentType = (pattern1: unknown)=>{
        if (typeof pattern1 !== 'string') throw new Error('expected string based argument');
        const patterns1: Record<string, string> = {
            dni: '[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]',
            nie: '[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]',
            pasaporte: '[0-9]+'
        };
        return patterns1[pattern1];
    };
    @action
    isEntityFullyCompleted(wrapper1: EntityWrapper<Teacher | Student>) {
        return wrapper1.status === 'complete';
    }
    @action
    getAvatarColorByCurrentStatus(wrapper1: EntityWrapper<Teacher | Student>) {
        const classByStatuses1 = {
            nofilled: 'bg-neutral-100',
            complete: 'bg-accent1-100',
            incomplete: 'bg-accent2-100',
            disabled: 'bg-neutral-200'
        };
        return wrapper1.entity.active === 'false' ? classByStatuses1.disabled : classByStatuses1[wrapper1.status];
    }
    removeError = (index1: number)=>{
        this.errors.splice(index1, 1);
    };
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section
      id="passengers"
      class="px-5 py-8 flex flex-col space-y-5 bg-white border border-neutral-200 rounded-3xl"
    >
      {{#if this.authenticatedRemoteData.isLoading}}
        <div class="flex justify-center">
          <Loader />
        </div>
      {{else}}
        <div class="pb-8 px-5">
          <ul
            class="-mb-px flex items-center gap-4 text-sm font-medium"
            {{on "click" this.setActiveTab}}
          >
            <li class="flex-1">
              <a
                data-tab="students"
                href="#"
                class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                  {{if
                    (this.isActiveTab 'students')
                    'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-accent1-300'
                    'text-gray-500 hover:text-black'
                  }}"
              >
                Estudiantes
              </a>
            </li>
            <li class="flex-1">
              <a
                data-tab="teachers"
                href="#"
                class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                  {{if
                    (this.isActiveTab 'teachers')
                    'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-accent1-300'
                    'text-gray-500 hover:text-black'
                  }}"
              >
                Profesores
              </a>
            </li>
          </ul>
        </div>

        <div
          class="flex flex-col gap-4 sm:flex-row sm:gap-0 justify-between items-center px-6 pb-5"
        >
          <div class="flex flex-col gap-4">
            <input
              {{this.registerInputFilter}}
              class="block w-full text-sm border-b px-1 outline-none"
              type="text"
              placeholder="Filtrar por nombre / apellido / num de identifiación"
              {{on "input" this.setfilterQuery}}
            />
            <div class="text-sm flex gap-2">
              <label class="capitalize pl-1" for="passanger-status">
                {{if (this.isActiveTab "students") "estudiantes" "profesores"}}
              </label>
              <select
                class="border-b px-1 outline-none"
                id="passanger-status"
                {{on "change" this.setfilterByStatus}}
              >
                <option value="all">
                  Todos
                </option>
                <option value="complete">
                  Completos
                </option>
                <option value="incomplete">
                  Incompletos
                </option>
                <option value="nofilled">
                  Sin rellenar
                </option>
              </select>
            </div>
          </div>
          {{#let
            (if
              (this.isActiveTab "students") this.totalStudentsCompleted this.totalTeachersCompleted
            )
            (if
              (this.isActiveTab "students")
              this.filteredStudents.length
              this.filteredTeachers.length
            )
            as |totalCompleted total|
          }}
            <div class="text-xs">
              Completados {{totalCompleted}} de {{total}}
            </div>
          {{/let}}
        </div>

        <div
          class="flex flex-col space-y-2 items-center px-6
            {{if (this.isActiveTab 'students') 'block opacity-100' 'hidden opacity-0'}}"
        >
          {{#each this.filteredStudents as |entityWrapper|}}
            {{#let entityWrapper.entity as |student|}}
              <HeadlessFormWrapper as |wrapper|>
                <Accordion
                  class="student w-full hover:shadow-md {{if student.errors 'error'}}"
                  @closeIfPassCondition={{(fn
                    wrapper.options.evaluateIfDataFormHasChanged student
                  )}}
                  as |accordion|
                >
                  <accordion.Summary
                    class="flex items-center justify-between gap-x-5 px-5
                      {{if student.errors 'error'}}
                      {{if (eq student.active 'false') 'bg-neutral-100'}}"
                  >
                    <div class="flex items-center gap-x-5">
                      <div class="relative inline-block">
                        <div
                          class="flex items-center justify-center w-10 h-10 rounded-full
                            {{(this.getAvatarColorByCurrentStatus entityWrapper)}}"
                        >
                          {{#if student.id}}
                            <span class="p-3 px-4 text-sm uppercase">
                              {{userInitials student.name}}
                            </span>
                          {{else}}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5V19M5 12H19"
                                stroke="#0A1126"
                                stroke-width="1.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          {{/if}}
                        </div>
                      </div>
                      <div>
                        {{#if student.id}}
                          <div class="line-clamp-1">
                            {{student.name}} {{student.lastName}}
                          </div>
                          <div class="text-xs">
                            Estudiante
                          </div>
                        {{else}}
                          <div class="line-clamp-1">
                            Añada un nuevo estudiante
                          </div>
                        {{/if}}
                      </div>
                    </div>
                    <div class="flex gap-2 lg:gap-4">
                      {{#if (and student.id (not (this.isEntityFullyCompleted entityWrapper)))}}
                        <PortalTargets class="z-10" />
                        <Tooltip class="z-10" @showArrow={{true}}>
                          <:tooltipable as |p|>
                            <Badge @type="warning" {{p.hook}}>
                              <div class="flex gap-2">
                                <PhWarning class="w-4 h-4" />
                                <span class="hidden lg:block">
                                  Incompleto
                                </span>
                              </div>
                            </Badge>
                          </:tooltipable>
                          <:content>
                            <div class="text-xs">
                              <div>
                                Campos incompletos:
                              </div>
                              <div class="flex flex-col">
                                {{#each entityWrapper.fieldsNotCompleted as |field|}}
                                  <span>
                                    &#8208; {{t
                                      (concat "page.trip-details.passengers-tab.form." field)
                                    }}
                                  </span>
                                {{/each}}
                              </div>
                            </div>
                          </:content>
                        </Tooltip>
                      {{/if}}
                      <div
                        class="hidden sm:inline-block text-xs
                          {{unless (and student.updatedAt student.id) 'invisible'}}"
                      >
                        <div>
                          Última modificacion
                        </div>
                        <div class="font-medium">
                          {{timeFromNow student.updatedAt}}
                        </div>
                      </div>
                    </div>
                  </accordion.Summary>
                  {{#if accordion.isOpen}}
                    <HeadlessForm
                      {{wrapper.options.registerForm}}
                      @data={{student}}
                      @validateOn="focusout"
                      @revalidateOn="input"
                      @onSubmit={{this.handleStudentSubmit}}
                      {{on "reset" wrapper.options.onResetFormData}}
                      {{on "input" (fn wrapper.options.evaluateIfDataFormHasChanged student)}}
                      as |form|
                    >
                      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-2 py-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <form.Field @name="name" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.name"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "name" student}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="lastName" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.lastName"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "lastName" student}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="birthday" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.birthday"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "birthday" student}}
                              required
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="gender" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.gender"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Select
                              disabled={{this.isFieldDisabled "gender" student}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              as |select|
                            >
                              <select.Option @value="">
                                -- Selecciona una opción --
                              </select.Option>
                              <select.Option @value="female">
                                Femenino
                              </select.Option>
                              <select.Option @value="male">
                                Masculino
                              </select.Option>
                            </field.Select>
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div>
                          <form.Field
                            @name="documentType"
                            @validate={{this.validatePair "document"}}
                            as |select|
                          >
                            <select.Label class="text-xs flex flex-col gap-1">
                              <div class="flex gap-2">
                                <span>
                                  {{t "page.trip-details.passengers-tab.form.documentType"}}
                                </span>
                                <select.Select
                                  disabled={{this.isFieldDisabled "documentType" student}}
                                  required={{and student.id (not (isBlank student.documentType))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if select.isInvalid 'border-red-500'}}
                                    -m-1 text-xs border rounded-xl disabled:cursor-not-allowed"
                                  as |select|
                                >
                                  <select.Option @value="">
                                    -- Selecciona una opción --
                                  </select.Option>
                                  <select.Option @value="dni">
                                    DNI
                                  </select.Option>
                                  <select.Option @value="nie">
                                    NIE
                                  </select.Option>
                                  <select.Option @value="pasaporte">
                                    Pasaporte
                                  </select.Option>
                                </select.Select>
                              </div>

                              <form.Field
                                @name="document"
                                @validate={{this.validatePair "documentType"}}
                                as |field|
                              >
                                <field.Input
                                  disabled={{this.isFieldDisabled "document" student}}
                                  required={{and student.id (not (isBlank student.document))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if
                                      field.isInvalid
                                      'border-red-500'
                                      'focus:border-accent1-300'
                                    }}
                                    h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                                  pattern="{{this.patternByDocumentType select.value}}"
                                />
                                <field.Errors class="text-xs text-red-500" />
                              </form.Field>
                              <select.Errors class="text-xs text-red-500" />
                            </select.Label>
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field
                            @name="documentExpirationDate"
                            @validate={{this.validateExpirationDate}}
                            as |field|
                          >
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.documentExpirationDate"}}
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "documentExpirationDate" student}}
                              required={{and
                                student.id
                                (not (isBlank student.documentExpirationDate))
                              }}
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentCountry" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.documentCountry"}}
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "documentCountry" student}}
                              required={{and student.id (not (isBlank student.documentCountry))}}
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-1 sm:col-span-2 gap-1 justify-stretch">
                          <form.Field @name="allergy" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.allergy"}}
                            </field.Label>
                            <field.Textarea
                              disabled={{this.isFieldDisabled "allergy" student}}
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-1 sm:col-span-2 md:col-span-3 gap-1">
                          <form.Field @name="observations" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.observations"}}
                            </field.Label>
                            <field.Textarea
                              disabled={{this.isFieldDisabled "observations" student}}
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                      </div>
                      <div class="flex justify-center gap-2 pt-5">
                        <ButtonText
                          type="submit"
                          @kind="outline"
                          @size="small"
                          @disabled={{this.isFieldDisabled null student}}
                        >
                          {{t "page.trip-details.passengers-tab.save-button"}}
                        </ButtonText>
                        {{#if wrapper.options.hasFormDataChanged}}
                          <ButtonText type="reset" @kind="outline" @size="small">
                            {{t "page.trip-details.passengers-tab.reset-button"}}
                          </ButtonText>
                        {{/if}}
                      </div>
                      {{#if student.errors}}
                        <div class="flex flex-col p-2 text-sm text-red-500">
                          <div>
                            Ha ocurrido un error
                          </div>
                          <div class="flex flex-col">
                            {{#each student.errors as |error|}}
                              {{#let (get error "path") (get error "message") as |path message|}}
                                <!-- @glint-ignore -->
                                <div>
                                  - {{path}} - {{message}}
                                </div>
                              {{/let}}
                            {{/each}}
                          </div>
                        </div>
                      {{/if}}
                    </HeadlessForm>
                  {{/if}}
                </Accordion>
              </HeadlessFormWrapper>
            {{/let}}
          {{/each}}
        </div>

        <div
          class="flex flex-col space-y-2 items-center px-6
            {{if (this.isActiveTab 'teachers') 'block opacity-100' 'hidden opacity-0'}}"
        >
          {{#each this.filteredTeachers as |entityWrapper|}}
            {{#let entityWrapper.entity as |teacher|}}
              <HeadlessFormWrapper as |wrapper|>
                <Accordion
                  class="teacher w-full hover:shadow-md {{if teacher.errors 'error'}}"
                  @closeIfPassCondition={{(fn
                    wrapper.options.evaluateIfDataFormHasChanged teacher
                  )}}
                  as |accordion|
                >
                  <accordion.Summary
                    class="flex items-center justify-between gap-x-5 px-5
                      {{unless teacher.id 'bg-[#E6F4F1]/50'}}
                      {{if teacher.errors 'error'}}"
                  >
                    <div class="flex items-center gap-x-5">
                      <div class="relative inline-block">
                        <div
                          class="flex items-center justify-center w-10 h-10 rounded-full
                            {{(this.getAvatarColorByCurrentStatus entityWrapper)}}"
                        >
                          {{#if teacher.id}}
                            <span class="p-3 px-4 text-sm uppercase">
                              {{userInitials teacher.name}}
                            </span>
                          {{else}}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5V19M5 12H19"
                                stroke="#0A1126"
                                stroke-width="1.75"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          {{/if}}
                        </div>
                      </div>
                      <div>
                        {{#if teacher.id}}
                          <div class="line-clamp-1">
                            {{teacher.name}} {{teacher.lastName}}
                          </div>
                          <div class="text-xs">
                            Profesor
                          </div>
                        {{else}}
                          <div class="line-clamp-1">
                            Añada un nuevo profesor
                          </div>
                        {{/if}}
                      </div>
                    </div>
                    <div class="flex gap-2 lg:gap-4">
                      {{#if (and teacher.id (not (this.isEntityFullyCompleted entityWrapper)))}}
                        <PortalTargets class="z-10" />
                        <Tooltip class="z-10" @showArrow={{true}}>
                          <:tooltipable as |p|>
                            <Badge @type="warning" {{p.hook}}>
                              <div class="flex gap-2">
                                <PhWarning class="w-4 h-4" />
                                <span class="hidden lg:block">
                                  Incompleto
                                </span>
                              </div>
                            </Badge>
                          </:tooltipable>
                          <:content>
                            <div class="text-xs">
                              <div>
                                Campos incompletos:
                              </div>
                              <div class="flex flex-col">
                                {{#each entityWrapper.fieldsNotCompleted as |field|}}
                                  <span>
                                    &#8208; {{t
                                      (concat "page.trip-details.passengers-tab.form." field)
                                    }}
                                  </span>
                                {{/each}}
                              </div>
                            </div>
                          </:content>
                        </Tooltip>
                      {{/if}}
                      <div
                        class="hidden sm:inline-block text-xs
                          {{unless (and teacher.updatedAt teacher.id) 'invisible'}}"
                      >
                        <div>
                          Última modificacion
                        </div>
                        <div class="font-medium">
                          {{timeFromNow teacher.updatedAt}}
                        </div>
                      </div>
                    </div>
                  </accordion.Summary>
                  {{#if accordion.isOpen}}
                    <HeadlessForm
                      {{wrapper.options.registerForm}}
                      @data={{teacher}}
                      @validateOn="focusout"
                      @revalidateOn="input"
                      {{on "reset" wrapper.options.onResetFormData}}
                      {{on "input" (fn wrapper.options.evaluateIfDataFormHasChanged teacher)}}
                      @onSubmit={{this.handleTeacherSubmit}}
                      as |form|
                    >
                      <div class="grid grid-cols-3 px-4 py-2 gap-4">
                        <div class="flex flex-col gap-1">
                          <form.Field @name="name" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.name"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "name"}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="lastName" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.lastName"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "lastName"}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="birthday" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.birthday"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "birthday"}}
                              required
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="gender" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.gender"}}
                              <span class="text-error-500">
                                *
                              </span>
                            </field.Label>
                            <field.Select
                              disabled={{this.isFieldDisabled "gender"}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              as |select|
                            >
                              <select.Option @value="">
                                -- Selecciona una opción --
                              </select.Option>
                              <select.Option @value="female">
                                Femenino
                              </select.Option>
                              <select.Option @value="male">
                                Masculino
                              </select.Option>
                            </field.Select>
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentType" as |select|>
                            <select.Label class="text-xs flex flex-col gap-1">
                              <div class="flex gap-2">
                                <span>
                                  {{t "page.trip-details.passengers-tab.form.documentType"}}
                                </span>
                                <select.Select
                                  disabled={{this.isFieldDisabled "documentType"}}
                                  required={{and teacher.id (not (isBlank teacher.documentType))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if select.isInvalid 'border-red-500'}}
                                    -m-1 text-xs border rounded-xl disabled:cursor-not-allowed"
                                  as |select|
                                >
                                  <select.Option @value="">
                                    -- Selecciona una opción --
                                  </select.Option>
                                  <select.Option @value="dni">
                                    DNI
                                  </select.Option>
                                  <select.Option @value="nie">
                                    NIE
                                  </select.Option>
                                  <select.Option @value="pasaporte">
                                    Pasaporte
                                  </select.Option>
                                </select.Select>
                              </div>

                              <form.Field @name="document" as |field|>
                                <field.Input
                                  disabled={{this.isFieldDisabled "document"}}
                                  required={{and teacher.id (not (isBlank teacher.document))}}
                                  {{on "invalid" this.validateInputNatively}}
                                  class="{{if
                                      field.isInvalid
                                      'border-red-500'
                                      'focus:border-accent1-300'
                                    }}
                                    h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                                  pattern="{{this.patternByDocumentType select.value}}"
                                />
                                <field.Errors class="text-xs text-red-500" />
                              </form.Field>
                              <select.Errors class="text-xs text-red-500" />
                            </select.Label>
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field
                            @name="documentExpirationDate"
                            @validate={{this.validateExpirationDate}}
                            as |field|
                          >
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.documentExpirationDate"}}
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "documentExpirationDate"}}
                              required={{and
                                teacher.id
                                (not (isBlank teacher.documentExpirationDate))
                              }}
                              type="date"
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="documentCountry" as |field|>
                            <field.Label class="text-xs">
                              {{t "page.trip-details.passengers-tab.form.documentCountry"}}
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "documentCountry"}}
                              required={{and teacher.id (not (isBlank teacher.documentCountry))}}
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="email" as |field|>
                            <field.Label class="text-xs">
                              Email:
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "email"}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                              pattern="[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col gap-1">
                          <form.Field @name="phone" as |field|>
                            <field.Label class="text-xs">
                              Teléfono:
                            </field.Label>
                            <field.Input
                              disabled={{this.isFieldDisabled "phone"}}
                              required
                              {{on "invalid" this.validateInputNatively}}
                              type="number"
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-3 gap-1 justify-stretch">
                          <form.Field @name="allergy" as |field|>
                            <field.Label class="text-xs">
                              Alergias:
                            </field.Label>
                            <field.Textarea
                              disabled={{this.isFieldDisabled "allergy"}}
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                        <div class="flex flex-col col-span-3 gap-1">
                          <form.Field @name="observations" as |field|>
                            <field.Label class="text-xs">
                              Observaciones:
                            </field.Label>
                            <field.Textarea
                              disabled={{this.isFieldDisabled "observations"}}
                              {{on "invalid" this.validateInputNatively}}
                              {{this.setupMutationObserverForTextArea}}
                              class="{{if
                                  field.isInvalid
                                  'border-red-500'
                                  'focus:border-accent1-300'
                                }}
                                text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                            />
                            <field.Errors class="text-xs text-red-500" />
                          </form.Field>
                        </div>
                      </div>
                      <div class="flex justify-center gap-2 pt-5">
                        <ButtonText
                          type="submit"
                          @kind="outline"
                          @size="small"
                          @disabled={{this.isFieldDisabled null}}
                        >
                          {{t "page.trip-details.passengers-tab.save-button"}}
                        </ButtonText>
                        {{#if wrapper.options.hasFormDataChanged}}
                          <ButtonText type="reset" @kind="outline" @size="small">
                            {{t "page.trip-details.passengers-tab.reset-button"}}
                          </ButtonText>
                        {{/if}}
                      </div>
                    </HeadlessForm>
                  {{/if}}
                </Accordion>
              </HeadlessFormWrapper>
            {{/let}}
          {{/each}}
        </div>
      {{/if}}
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default PassengersTab;
