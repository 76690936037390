import Helper from '@ember/component/helper';

type EventedFn = (event: Event) => void;
export interface PreventDefaultSignature {
  Args: {
    Positional: [EventedFn?];
  };
  Return: EventedFn;
}
export default class PreventDefault extends Helper<PreventDefaultSignature> {
  compute([handler]: [EventedFn?]) {
    return function (event: Event) {
      if (event) {
        event.preventDefault();
        if (handler) handler(event);
      }
    };
  }
}
