import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Panel from './panel';
export default class PanelList extends Component<{
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
        default: [];
    };
}> {
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <div ...attributes>
      {{yield (component Panel onInsertPanel=@onInsertPanel activePanel=@activePanel)}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
