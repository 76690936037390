import EmberRouter from '@ember/routing/router';
import { properLinks } from 'ember-primitives/proper-links';
import config from 'vfc-client/config/environment';
@properLinks
export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('request-password');
  this.route('change-password');

  this.route('authenticated', { path: '' }, function () {
    this.route('landing-page', { path: '/' });
    this.route('trip-details', function () {
      this.route('index', { path: '/:id' });
    });
    this.route('my-profile');
    this.route('help');
    this.route('not-found', { path: '/*path' });
  });
});
