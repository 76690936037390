import { template } from "@ember/template-compiler";
import Route from 'ember-route-template';
import Loader from 'vfc-client/components/ui/loader';
export default Route(template(`
    <div class="w-full flex items-center justify-center">
      <Loader />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
