import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import PhBed from 'ember-phosphor-icons/components/ph-bed';
import PhCheckCircle from 'ember-phosphor-icons/components/ph-check-circle';
import PhInfo from 'ember-phosphor-icons/components/ph-info';
import PhPlus from 'ember-phosphor-icons/components/ph-plus';
import PhUser from 'ember-phosphor-icons/components/ph-user';
import PhX from 'ember-phosphor-icons/components/ph-x';
import { Modal, PortalTargets } from 'ember-primitives';
import type ModalDialog from 'ember-primitives/components/dialog';
import { and, not } from 'ember-truth-helpers';
import { TrackedArray } from 'tracked-built-ins';
import { Accordion } from 'vfc-client/components/ui/accordion';
import Loader from 'vfc-client/components/ui/loader';
import Tooltip from 'vfc-client/components/ui/tooltip';
import preventDefault from 'vfc-client/helpers/prevent-default';
import Room from 'vfc-client/models/room';
import Student from 'vfc-client/models/student';
import disabled from 'vfc-client/modifiers/disabled';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type { HTMLElementEvent } from 'vfc-client/utility-types';
import { timeFromNow } from 'vfc-client/utils/date-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
const returnFn = <T>(value1: T)=>value1;
const StudentRow: TOC<{
    Element: HTMLButtonElement;
    Args: {
        isInTheRoom: boolean;
        isRoomAvailable: boolean;
        tripId: number;
        student: RoomStudent;
        onToggleStudent: ({ addUser, tripId, studentId }: {
            addUser: boolean;
            tripId: number;
            studentId: string | number | undefined;
        }) => void;
    };
}> = template(`
  {{#let (and (not @isInTheRoom) (not @isRoomAvailable)) as |isAddUserDisabled|}}
    <button
      type="button"
      class="group hover:bg-accent1-500 hover:text-white py-3"
      {{disabled when=isAddUserDisabled}}
      {{on
        "click"
        (fn @onToggleStudent (hash addUser=(not @isInTheRoom) tripId=@tripId studentId=@student.id))
      }}
    >
      <div class="z-10">
        <PortalTargets />
      </div>
      <Tooltip @placement={{"top-end"}} @showIfPassCondition={{(fn returnFn isAddUserDisabled)}}>
        <:tooltipable as |p|>
          <div class="flex justify-between items-center px-2" {{p.hook}}>
            <div class="flex gap-2 items-center">
              <PhUser class="w-5 h-5" />
              <span>
                {{@student.name}} {{@student.lastName}}
              </span>
            </div>
            <div>
              {{#if @isInTheRoom}}
                <div class="flex p-1 group/actions hover:bg-white rounded-lg">
                  <PhCheckCircle class="group-hover:hidden fill-accent1-500 w-5 h-5" />
                  {{! Trash icon }}
                  <svg
                    class="hidden group-hover:inline w-5 h-5 stroke-current group-hover/actions:stroke-error-600"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              {{else}}
                <div class="p-1 invisible group-hover:visible">
                  {{#unless @isRoomAvailable}}
                    <PhX class="absolute w-5 h-5 fill-error-600" />
                  {{/unless}}
                  <PhBed class="w-5 h-5 flex-shrink-0 fill-white" />
                </div>
              {{/if}}
            </div>
          </div>
        </:tooltipable>
        <:content>
          <span class="text-sm">
            El cupo de camas esta completo
          </span>
        </:content>
      </Tooltip>
    </button>
    <hr />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let RoomStudent = class RoomStudent extends Student {
    @tracked
    tripRoomId: number | null = null;
    constructor(student1: RoomStudent){
        super(student1);
        this.tripRoomId = student1.tripRoomId;
    }
};
interface RoomSignature {
    Element: HTMLElement;
    Args: {
        tripId: number;
        room: Room;
        disabled: boolean;
    };
}
export default class RoomComponent extends Component<RoomSignature> {
    @service('auth/http-client')
    httpClient: AuthenticatedHttpClient;
    @tracked
    filterQuery: string;
    @tracked
    loadingAvailableStudents: boolean = true;
    // TODO: this ugly workaround is required because the server does not return the tripRoomId
    availableStudents: TrackedArray<RoomStudent> = new TrackedArray(this.args.room.students.map((s1)=>{
        s1.tripRoomId = this.args.room.id;
        return s1;
    }));
    inputFilter: HTMLInputElement;
    registerInputFilter = modifier((element1: HTMLInputElement)=>{
        this.inputFilter = element1;
    });
    modal: ModalDialog;
    @action
    registerModal(m1: ModalDialog) {
        this.modal = m1;
    }
    @cached
    get totalAvailableStudentsWithRoomAssigned() {
        return this.availableStudents.reduce((acc1, s1)=>{
            if (isPresent(s1.tripRoomId)) acc1++;
            return acc1;
        }, 0);
    }
    @action
    filterByFullname({ target: target1 }: HTMLElementEvent<HTMLInputElement>) {
        if (target1 instanceof HTMLInputElement) {
            this.filterQuery = target1.value;
        }
    }
    filterStudents = (students1: Array<RoomStudent>)=>{
        return students1.filter((student1)=>{
            if (isBlank(this.filterQuery)) return true;
            const fullname1 = `${student1.name} ${student1.lastName}`.toLowerCase();
            return fullname1.includes(this.filterQuery.toLowerCase());
        });
    };
    get isRoomAvailable() {
        return this.args.room.beds > this.totalAvailableStudentsWithRoomAssigned;
    }
    get roomStudents() {
        return this.availableStudents.filter((rs1: RoomStudent)=>isPresent(rs1.tripRoomId));
    }
    get availableBeds() {
        const availableBeds1 = this.args.room.beds - this.roomStudents.length;
        return new Array(availableBeds1);
    }
    @action
    loadAvailableStudents() {
        const gender1 = this.args.room.type;
        const query1 = [
            'male',
            'female'
        ].includes(gender1) ? `?gender=${gender1}` : ``;
        this.loadingAvailableStudents = true;
        this.httpClient.GET<Array<RoomStudent>>({
            url: urlFor(`/trip/${this.args.tripId}/rooms/${this.args.room.id}/available-students${query1}`)
        }).then((students1)=>{
            const _availableRoomStudents1 = new Array<RoomStudent>();
            students1.forEach((student1: RoomStudent)=>{
                _availableRoomStudents1.push(new RoomStudent(student1));
            });
            this.availableStudents.splice(0, this.availableStudents.length, ..._availableRoomStudents1);
        }).finally(()=>{
            this.loadingAvailableStudents = false;
        });
    }
    @action
    onToggleStudent({ addUser: addUser1, tripId: tripId1, studentId: studentId1 }: {
        addUser: boolean;
        tripId: number;
        studentId: string | number | undefined;
    }) {
        if (addUser1) {
            const headers1 = new Headers({
                'Content-Type': 'application/json'
            });
            this.httpClient.POST({
                url: urlFor(`/trip/${tripId1}/rooms/${this.args.room.id}/student`),
                headers: headers1,
                body: {
                    students: [
                        studentId1
                    ]
                }
            }).then(()=>{
                const index1 = this.availableStudents.findIndex((fs1)=>fs1.id === studentId1);
                const student1 = this.availableStudents[index1];
                if (!student1) throw new Error('missing student');
                student1['tripRoomId'] = this.args.room.id;
            });
        } else {
            this.httpClient.DELETE({
                url: urlFor(`/trip/${tripId1}/rooms/${this.args.room.id}/student/${studentId1}`)
            }).then(()=>{
                const index1 = this.availableStudents.findIndex((fs1)=>fs1.id === studentId1);
                const student1 = this.availableStudents[index1];
                if (!student1) throw new Error('missing student');
                student1['tripRoomId'] = null;
            });
        }
    }
    @action
    onOpenModal() {
        this.loadAvailableStudents();
        this.modal.open();
    }
    @action
    onCloseModal() {
        this.filterQuery = '';
        this.inputFilter.value = '';
    }
    static{
        template(`
    <Accordion class="w-full hover:shadow-md" as |accordion|>
      <accordion.Summary class="flex flex-col gap-2 sm:flex-row items-center justify-between !px-5">
        <div class="flex flex-col items-center sm:items-baseline">
          <div class="font-semibold text-2xl">
            {{@room.name}}
          </div>
          <div class="flex gap-2 items-center text-sm">
            <PhBed class="block lg:hidden flex-shrink-0" />
            <span>
              <span class="hidden lg:inline">
                Camas
              </span>
              completas
              {{this.roomStudents.length}}
              de
              {{@room.beds}}
            </span>
          </div>
        </div>
        <div class="flex gap-2">
          <div class="text-xs">
            <div>
              Última modificacion
            </div>
            <div class="font-medium">
              {{timeFromNow @room.updatedAt}}
            </div>
          </div>
          <Modal @onClose={{this.onCloseModal}} as |m|>
            {{(this.registerModal m)}}
            <m.Dialog
              class="rounded-lg px-2 min-w-[min(900px,80%)]"
              {{on "click" (preventDefault)}}
            >
              {{#if m.isOpen}}
                <div class="z-10">
                  <PortalTargets />
                </div>
                <main class="px-6 pt-4 pb-8 flex flex-col">
                  <header class="w-full flex flex-col">
                    <button class="self-end" type="button" {{on "click" m.close}}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#0A1126"
                          stroke-width="1.75"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <div class="flex flex-col items-center pb-4 px-4">
                      <h4>
                        {{@room.name}}
                      </h4>
                      <div class="flex gap-2 items-center text-sm">
                        <PhBed class="block lg:hidden" />
                        <span>
                          <span class="hidden lg:inline">
                            Camas
                          </span>
                          completas
                          {{this.totalAvailableStudentsWithRoomAssigned}}/{{@room.beds}}
                        </span>
                      </div>
                    </div>
                  </header>
                  <div class="flex flex-col text-left">
                    <b class="py-2 underline underline-offset-2 decoration-accent1-300">
                      Seleccione los alumnos a incluir:
                    </b>
                    <input
                      class="block w-[21rem] text-sm border-b px-1 mt-1 mb-3 outline-none"
                      type="text"
                      placeholder="Filtrar alumno por nombre"
                      aria-label="Filtrar alumno por nombre"
                      {{this.registerInputFilter}}
                      {{on "input" this.filterByFullname}}
                    />

                    <div class="flex flex-col max-h-[60vh] overflow-scroll">
                      {{#if this.loadingAvailableStudents}}
                        <div class="flex justify-center">
                          <Loader />
                        </div>
                      {{else}}
                        {{#let (this.filterStudents this.availableStudents) as |students|}}
                          {{#if (isEmpty students)}}
                            <span class="text-sm">
                              No hay resultados.
                            </span>
                          {{else}}
                            {{#each students as |student|}}
                              <StudentRow
                                @student={{student}}
                                @isRoomAvailable={{this.isRoomAvailable}}
                                @isInTheRoom={{(isPresent student.tripRoomId)}}
                                @tripId={{@tripId}}
                                @onToggleStudent={{this.onToggleStudent}}
                              />
                            {{/each}}
                          {{/if}}
                        {{/let}}
                      {{/if}}
                    </div>
                  </div>
                </main>
              {{/if}}
            </m.Dialog>

            <button
              type="button"
              class="group p-2 rounded-lg
                {{if @disabled 'bg-neutral-100 cursor-not-allowed' 'hover:bg-neutral-900'}}"
              disabled={{@disabled}}
              {{on "click" this.onOpenModal}}
            >
              <svg
                class="w-4 h-4
                  {{if
                    @disabled
                    'stroke-neutral-600'
                    'stroke-neutral-900 group-hover:stroke-white'
                  }}"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 2L22 6M2 22L3.2764 17.3199C3.35968 17.0145 3.40131 16.8619 3.46523 16.7195C3.52199 16.5931 3.59172 16.4729 3.67332 16.3609C3.76521 16.2348 3.87711 16.1229 4.1009 15.8991L14.4343 5.56569C14.6323 5.36768 14.7313 5.26867 14.8455 5.23158C14.9459 5.19895 15.0541 5.19895 15.1545 5.23158C15.2687 5.26867 15.3677 5.36768 15.5657 5.56569L18.4343 8.43431C18.6323 8.63232 18.7313 8.73133 18.7684 8.84549C18.8011 8.94591 18.8011 9.05409 18.7684 9.15451C18.7313 9.26867 18.6323 9.36768 18.4343 9.56569L8.1009 19.8991C7.87711 20.1229 7.76521 20.2348 7.63908 20.3267C7.52709 20.4083 7.40692 20.478 7.28052 20.5348C7.13815 20.5987 6.98548 20.6403 6.68014 20.7236L2 22Z"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </Modal>
        </div>
      </accordion.Summary>
      <hr class="h-px bg-neutral-400" />
      {{#if (isPresent @room.observations)}}
        <div class="flex gap-2 items-center text-sm font-semibold text-neutral-900 px-5 mt-2">
          <PhInfo class="w-4 h-4">
            <title>
              Observaciones
            </title>
          </PhInfo>
          <span class="text-sm font-normal">
            {{@room.observations}}
          </span>
        </div>
      {{/if}}
      <table class="w-full mt-2">
        <thead>
          <tr>
            <th class="text-left pl-5">
              Nombre
            </th>
            <th class="text-center pr-5">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {{#each this.availableBeds}}
            <tr
              class="{{if
                  @disabled
                  'bg-neutral-100 cursor-not-allowed'
                  'hover:bg-accent1-500 hover:text-white'
                }}"
            >
              <td class="pl-5 w-4/5">
                <div class="flex gap-2 items-center">
                  <PhBed class="flex-shrink-0" />
                  <span class="line-clamp-1">
                    Cama disponible
                  </span>
                </div>
              </td>
              <td class="text-center pr-5 w-2/5">
                <button
                  type="button"
                  disabled={{@disabled}}
                  class="group p-1 my-1
                    {{if @disabled 'cursor-not-allowed' 'hover:bg-white rounded-lg'}}"
                  {{on "click" this.onOpenModal}}
                >
                  <PhPlus class="w-4 h-4 stroke-current group-hover:fill-neutral-900" />
                </button>
              </td>
            </tr>
          {{/each}}
          {{#each this.roomStudents as |student|}}
            <tr
              class="{{if
                  @disabled
                  'bg-neutral-100 cursor-not-allowed'
                  'hover:bg-accent1-500 hover:text-white'
                }}"
            >
              <td class="pl-5 w-4/5">
                <div class="flex gap-2 items-center">
                  <PhUser class="flex-shrink-0" />
                  <span class="line-clamp-1">
                    {{student.name}}
                    {{student.lastName}}
                  </span>
                </div>
              </td>
              <td class="text-center pr-5 w-2/5">
                <button
                  type="button"
                  disabled={{@disabled}}
                  class="group p-1 my-1
                    {{if @disabled 'cursor-not-allowed' 'hover:bg-white rounded-lg'}}"
                  {{on
                    "click"
                    (fn
                      this.onToggleStudent (hash addUser=false tripId=@tripId studentId=student.id)
                    )
                  }}
                >
                  {{! Trash icon }}
                  <svg
                    class="w-4 h-4 stroke-current
                      {{unless @disabled 'group-hover:stroke-error-600'}}"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          {{/each}}
        </tbody>
      </table>
    </Accordion>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
