/*
 * Extended remote data utility from
 * https://github.com/universal-ember/reactiveweb/blob/main/reactiveweb/src/remote-data.ts
 *
 */

import { waitForPromise } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import type { ResourceAPI } from 'ember-resources';
import { resource, resourceFactory } from 'ember-resources';
import type { Session } from 'ember-simple-auth/services/session';

type FetchOptions = Parameters<typeof fetch>[1];

/**
 * @protected
 */
export class State<T = unknown> {
  /**
   * If an exception was thrown while making the request, the error
   * thrown will be here.
   */
  @tracked error: Error | null = null;
  /**
   * The resolved value of the fetch request
   */
  @tracked value: T | null = null;

  /**
   * HTTP status code.
   */
  @tracked status: null | number = null;

  /**
   * True if the request has succeeded
   */
  @tracked isResolved = false;

  /**
   * True if the request has failed
   */
  @tracked isRejected = false;

  /**
   * Fetch promise
   */
  declare fetchPromise: Promise<T>;

  /**
   * true if the request has finished
   */
  get isFinished() {
    return this.isResolved || this.isRejected;
  }

  /**
   * Alias for `isFinished`
   * which is in turn an alias for `isResolved || isRejected`
   */
  get isSettled() {
    return this.isFinished;
  }

  /**
   * Alias for isLoading
   */
  get isPending() {
    return this.isLoading;
  }

  /**
   * true if the fetch request is in progress
   */
  get isLoading() {
    return !this.isFinished;
  }

  /**
   * true if the request throws an exception
   * or if the request.status is >= 400
   */
  get isError() {
    let httpError = this.status && this.status >= 400;
    let promiseThrew = this.isRejected;

    return httpError || promiseThrew;
  }
}

function remoteData<T = unknown>(
  { on }: ResourceAPI,
  url: string,
  options: FetchOptions = {}
): State<T> {
  let state = new State<T>();
  let controller = new AbortController();

  on.cleanup(() => controller.abort());

  const fetchPromise = fetch(url, { signal: controller.signal, ...options }).then((response) => {
    state.status = response.status;

    if (response.ok) {
      if (response.headers.get('Content-Type')?.includes('json')) {
        return response.json();
      }

      return response.text();
    } else {
      return Promise.reject(response);
    }
  });
  state.fetchPromise = fetchPromise;
  waitForPromise(
    fetchPromise
      .then((data) => {
        state.isResolved = true;
        state.value = data;
      })
      .catch((error) => {
        state.isRejected = true;
        state.error = error;
      })
  );

  return state;
}

export function AuthenticatedRemoteData<T = unknown>(url: string, options?: FetchOptions): State<T>;

export function AuthenticatedRemoteData<T = unknown>(url: () => string): State<T>;

export function AuthenticatedRemoteData<T = unknown>(
  options: () => { url: string } & FetchOptions
): State<T>;

export function AuthenticatedRemoteData<T = unknown>(
  url: string | (() => string) | (() => { url: string } & FetchOptions),
  opts?: FetchOptions
) {
  return resource((hooks) => {
    let result = typeof url === 'string' ? url : url();
    let targetUrl: string;
    let options: FetchOptions = {};
    let { owner } = hooks;
    const sessionService = owner.lookup('service:session') as unknown as Session;

    if (typeof result === 'string') {
      targetUrl = result;
    } else {
      let { url, ...opts } = result;

      targetUrl = url;
      options = opts;
    }

    if (opts) {
      options = { ...options, ...opts };
    }

    //authenticated header
    const {
      authenticated: { token },
    } = sessionService.data as { authenticated: { token: string } }; //TODO type refactoring
    options = {
      ...options,
      ...{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };

    return remoteData<T>(hooks, targetUrl, options);
  });
}

resourceFactory(AuthenticatedRemoteData);
