import { template } from "@ember/template-compiler";
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
export default class ButtonText extends Component<{
    Element: HTMLButtonElement;
    Args: {
        kind: 'ghost | ghost-inverse';
        size: 'small | xsmall';
        onClick?: (event: Event) => void;
    };
}> {
    static KIND = {
        ghost: 'bg-transparent text-neutral-900 hover:bg-neutral-100',
        'ghost-inverse': 'bg-transparent text-white hover:bg-neutral-900/20'
    };
    static SIZE = {
        small: 'w-10 h-10',
        xsmall: 'w-6 h-6'
    };
    get kind() {
        return this.args.kind ?? 'ghost';
    }
    get size() {
        return this.args.size ?? 'small';
    }
    @action
    onClick(event1: Event) {
        if (this.args.onClick) this.args.onClick(event1);
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <button
      type="button"
      class="rounded-md {{get ButtonText.KIND this.kind}} {{get ButtonText.SIZE this.size}}"
      {{on 'click' this.onClick}}
      ...attributes>
    {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
