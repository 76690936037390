import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { use } from 'ember-resources';
import config from 'vfc-client/config/environment';
import ResponseError from 'vfc-client/models/response-error';
import type { Trip } from 'vfc-client/types/trip';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';

export default class LandingPage extends Route {
  @service declare router: RouterService;

  @use authenticatedRemoteData = AuthenticatedRemoteData<Array<Trip>>(
    () => `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/trip/my-trips`
  );

  myTrips: Array<Trip> = new Array();

  async beforeModel() {
    try {
      this.myTrips = await this.authenticatedRemoteData.fetchPromise;

      if (1 === this.myTrips.length) {
        const [trip] = this.myTrips;
        if (!trip) throw new Error('trip is mandatory');
        this.router.transitionTo('authenticated.trip-details', trip.id);
      }
    } catch (error: unknown) {
      if (error instanceof Response) {
        error.json().then((json) => {
          throw new ResponseError((error as Response).status, json);
        });
      }
    }
  }

  model() {
    const _myTrips = this.myTrips;
    return {
      get myTrips() {
        return _myTrips;
      },
    };
  }
}
