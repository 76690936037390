import type Owner from '@ember/owner';
import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { FormData } from 'ember-headless-form';
import config from 'vfc-client/config/environment';
import type { MyProfileType } from 'vfc-client/routes/authenticated/my-profile';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type { TODO } from 'vfc-client/utility-types';

export default class UserService extends Service {
  @service session: TODO;
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  @tracked _username!: string;
  #userProfile: MyProfileType | undefined;

  constructor(owner: Owner) {
    super(owner);
    if (this.session.isAuthenticated) {
      this.loadUserProfile();
    }
  }

  async loadUserProfile() {
    const userProfile = await this.authenticatedHttpClient.GET<MyProfileType>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/user/profile`,
    });

    this.#userProfile = userProfile;
    this._username = userProfile.username;

    sessionStorage.setItem(`${this.username}.userProfile`, JSON.stringify(userProfile));
  }

  async saveUserProfile(userProfile: MyProfileType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    this.#userProfile = await this.authenticatedHttpClient.PUT<MyProfileType>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/user/profile`,
      headers,
      body: {
        name: userProfile.name,
        email: userProfile.email,
        phone: userProfile.phone,
        password: userProfile.password,
      },
    });
  }

  resetPassword(formData: FormData): Promise<Response> {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    return this.authenticatedHttpClient.POST<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/auth/request-password`,
      body: formData,
      headers,
    });
  }

  changePassword(formData: FormData): Promise<Response> {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    return this.authenticatedHttpClient.POST<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/auth/change-password`,
      body: formData,
      headers,
    });
  }

  get username() {
    return this._username;
  }

  get userProfile() {
    return this.#userProfile;
  }

  clearUserProfile() {
    sessionStorage.removeItem(`${this.username}.userProfile`);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
