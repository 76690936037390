import { registerDestructor } from '@ember/destroyable';
import type Owner from '@ember/owner';
import Modifier, { type ArgsFor } from 'ember-modifier';

const HAS_WINDOWS = typeof window !== 'undefined';
const HAS_NAVIGATOR = typeof navigator !== 'undefined';
const IS_TOUCH =
  HAS_WINDOWS && ('ontouchstart' in window || (HAS_NAVIGATOR && navigator.maxTouchPoints > 0));
const EVENTS = IS_TOUCH ? ['touchstart'] : ['click'];

const IDS = new WeakMap();

const getEventNames = ({ event, events }: NamedParams) => {
  if (events) {
    return events;
  }
  if (event) {
    return [event];
  }
  return EVENTS;
};

const onClickOutside = (
  { target }: Event,
  callback: () => void,
  container?: HTMLElement,
  useCapture?: boolean
) => {
  const targetEl = target as HTMLElement;
  const isClickOutside = !container?.contains(targetEl) && document.body.contains(targetEl);

  if (isClickOutside) {
    callback.apply(this);
  }
};

interface NamedParams {
  event?: string;
  events?: Array<string>;
}

export interface ClickOutsideSignature {
  Element: HTMLElement;
  Args: {
    Positional: [callback: (event: Event) => void, useCapture?: boolean];
    Named: NamedParams;
  };
}

export default class ClickOutside extends Modifier<ClickOutsideSignature> {
  handlers: Array<[string, (event: Event) => void]> = [];
  useCapture = false;

  constructor(owner: Owner, args: ArgsFor<ClickOutsideSignature>) {
    super(owner, args);
    registerDestructor(this, () => {
      this.handlers.forEach(([eventName, handler]) => {
        document.documentElement.removeEventListener(eventName, handler, this.useCapture);
      });
    });
  }

  modify(
    element: HTMLElement,
    [callback, useCapture]: [(event: Event) => void, boolean],
    hashParams?: NamedParams
  ) {
    this.useCapture = useCapture;
    const refEvent = new Event('clickReference');
    const events = getEventNames(hashParams ?? {});
    const isFunction = typeof callback === 'function';
    if (!isFunction) {
      throw new Error('{{click-outside}}: The callback must be a function.');
    }

    events.forEach((eventName: string) => {
      const handler = (event: Event) => {
        if (refEvent.timeStamp > event.timeStamp) {
          return;
        }
        onClickOutside(event, () => callback(event), element, this.useCapture);
      };
      this.handlers.push([eventName, handler]);
      document.documentElement.addEventListener(eventName, handler, this.useCapture);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'click-outside': typeof ClickOutside;
  }
}
