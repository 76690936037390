import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

import Student from './student';

class RoomStudent extends Student {
  @tracked tripRoomId: number | null = null;
  constructor(student: RoomStudent) {
    super(student);
    this.tripRoomId = student.tripRoomId;
  }
}

export default class Room {
  id: number;
  name: string;
  type: string;
  beds: number;
  observations: string = '';
  updatedAt: string | Date;
  students: TrackedArray<RoomStudent>;
  constructor({
    id,
    name,
    type,
    students,
    beds,
    updatedAt,
    observations,
  }: {
    id: number;
    name: string;
    type: string;
    students: Array<RoomStudent>;
    beds: number;
    updatedAt: Date | string;
    observations: string;
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.students = new TrackedArray<RoomStudent>(students.map((s) => new RoomStudent(s)));
    this.beds = beds;
    this.updatedAt = updatedAt;
    this.observations = observations;
  }
}
