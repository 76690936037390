
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("vfc-client/app", function(){ return i("vfc-client/app.ts");});
d("vfc-client/authenticators/token", function(){ return i("vfc-client/authenticators/token.ts");});
d("vfc-client/config/environment", function(){ return i("vfc-client/config/environment.js");});
d("vfc-client/formats", function(){ return i("vfc-client/formats.js");});
d("vfc-client/instance-initializers/error-handler", function(){ return i("vfc-client/instance-initializers/error-handler.ts");});
d("vfc-client/models/response-error", function(){ return i("vfc-client/models/response-error.ts");});
d("vfc-client/models/room", function(){ return i("vfc-client/models/room.ts");});
d("vfc-client/models/student", function(){ return i("vfc-client/models/student.ts");});
d("vfc-client/models/teacher", function(){ return i("vfc-client/models/teacher.ts");});
d("vfc-client/router", function(){ return i("vfc-client/router.ts");});
d("vfc-client/services/auth/http-client", function(){ return i("vfc-client/services/auth/http-client.ts");});
d("vfc-client/services/notification-service", function(){ return i("vfc-client/services/notification-service.ts");});
d("vfc-client/services/user-service", function(){ return i("vfc-client/services/user-service.ts");});
d("vfc-client/types/trip", function(){ return i("vfc-client/types/trip.ts");});
d("vfc-client/utils/array-utils", function(){ return i("vfc-client/utils/array-utils.ts");});
d("vfc-client/utils/authenticated-remote-data", function(){ return i("vfc-client/utils/authenticated-remote-data.ts");});
d("vfc-client/utils/date-utils", function(){ return i("vfc-client/utils/date-utils.ts");});
d("vfc-client/utils/dom-utils", function(){ return i("vfc-client/utils/dom-utils.ts");});
d("vfc-client/utils/file-utils", function(){ return i("vfc-client/utils/file-utils.ts");});
d("vfc-client/utils/sort-utils", function(){ return i("vfc-client/utils/sort-utils.ts");});
d("vfc-client/utils/string-utils", function(){ return i("vfc-client/utils/string-utils.ts");});
d("vfc-client/utils/url-utils", function(){ return i("vfc-client/utils/url-utils.ts");});
d("vfc-client/utils/user-utils", function(){ return i("vfc-client/utils/user-utils.ts");});
d("vfc-client/services/page-title", function(){ return i("vfc-client/services/page-title.js");});
d("vfc-client/services/ember-primitives/setup", function(){ return i("vfc-client/services/ember-primitives/setup.js");});
d("vfc-client/services/cookies", function(){ return i("vfc-client/services/cookies.js");});
d("vfc-client/initializers/ember-simple-auth", function(){ return i("vfc-client/initializers/ember-simple-auth.js");});
d("vfc-client/services/session", function(){ return i("vfc-client/services/session.js");});
d("vfc-client/session-stores/application", function(){ return i("vfc-client/session-stores/application.js");});
d("vfc-client/utils/inject", function(){ return i("vfc-client/utils/inject.js");});
d("vfc-client/utils/is-fastboot", function(){ return i("vfc-client/utils/is-fastboot.js");});
d("vfc-client/utils/location", function(){ return i("vfc-client/utils/location.js");});
d("vfc-client/utils/objects-are-equal", function(){ return i("vfc-client/utils/objects-are-equal.js");});
d("vfc-client/component-managers/glimmer", function(){ return i("vfc-client/component-managers/glimmer.js");});
d("vfc-client/services/-ensure-registered", function(){ return i("vfc-client/services/-ensure-registered.js");});
d("vfc-client/services/intl", function(){ return i("vfc-client/services/intl.js");});
d("vfc-client/utils/intl/missing-message", function(){ return i("vfc-client/utils/intl/missing-message.js");});
d("vfc-client/container-debug-adapter", function(){ return i("vfc-client/container-debug-adapter.js");});
d("vfc-client/templates/application", function(){ return i("vfc-client/templates/application.ts");});
d("vfc-client/routes/application", function(){ return i("vfc-client/routes/application.ts");});
d("vfc-client/routes/authenticated", function(){ return i("vfc-client/routes/authenticated.ts");});
d("vfc-client/templates/authenticated/landing-page", function(){ return i("vfc-client/templates/authenticated/landing-page.ts");});
d("vfc-client/routes/authenticated/landing-page", function(){ return i("vfc-client/routes/authenticated/landing-page.ts");});
d("vfc-client/templates/authenticated/my-profile", function(){ return i("vfc-client/templates/authenticated/my-profile.ts");});
d("vfc-client/routes/authenticated/my-profile", function(){ return i("vfc-client/routes/authenticated/my-profile.ts");});
d("vfc-client/templates/authenticated/trip-details/index", function(){ return i("vfc-client/templates/authenticated/trip-details/index.ts");});
d("vfc-client/routes/authenticated/trip-details/index", function(){ return i("vfc-client/routes/authenticated/trip-details/index.ts");});
d("vfc-client/templates/authenticated/trip-details/documents-tab", function(){ return i("vfc-client/templates/authenticated/trip-details/documents-tab.ts");});
d("vfc-client/templates/authenticated/trip-details/passengers-tab", function(){ return i("vfc-client/templates/authenticated/trip-details/passengers-tab.ts");});
d("vfc-client/templates/authenticated/trip-details/payments-tab", function(){ return i("vfc-client/templates/authenticated/trip-details/payments-tab.ts");});
d("vfc-client/templates/authenticated/trip-details/rooms-tab", function(){ return i("vfc-client/templates/authenticated/trip-details/rooms-tab.ts");});
d("vfc-client/templates/authenticated/trip-details/trip-details-tab", function(){ return i("vfc-client/templates/authenticated/trip-details/trip-details-tab.ts");});
d("vfc-client/templates/authenticated/help", function(){ return i("vfc-client/templates/authenticated/help.ts");});
d("vfc-client/templates/authenticated/landing-page-loading", function(){ return i("vfc-client/templates/authenticated/landing-page-loading.ts");});
d("vfc-client/templates/change-password", function(){ return i("vfc-client/templates/change-password.ts");});
d("vfc-client/routes/change-password", function(){ return i("vfc-client/routes/change-password.ts");});
d("vfc-client/templates/login", function(){ return i("vfc-client/templates/login.ts");});
d("vfc-client/routes/login", function(){ return i("vfc-client/routes/login.ts");});
d("vfc-client/templates/request-password", function(){ return i("vfc-client/templates/request-password.ts");});
d("vfc-client/routes/request-password", function(){ return i("vfc-client/routes/request-password.ts");});
d("vfc-client/templates/not-found", function(){ return i("vfc-client/templates/not-found.ts");});





if (!runningTests) {
  i("../app").default.create({"DATE":{"DEFAULT_FORMAT":"DD/MM/YYYY","USER_FRIENDLY_FORMAT":"D MMM YYYY","DATE_TYPE_FORMAT":"YYYY-MM-DD"},"API":{"ADMIN_FINDECURSO_PREFIX":"https://api-admin.findecursocolegio.com/v1/client"},"NOTIFICATION_TIMEOUT":1500,"GOOGLE_MAPS_API_KEY":"AIzaSyDZvD86sHvqzUzJKG7RPIkK3A56H7vuk_g"});
}

