import { isBlank } from '@ember/utils';
import dayjs from 'dayjs';
import { TrackedObject } from 'tracked-built-ins';
import config from 'vfc-client/config/environment';

export default class Student extends TrackedObject {
  type = 'Student'; //TODO: Improve this
  temporaryId?: string;
  id: number | string | undefined;
  document: string;
  documentType: 'dni' | 'nie' | 'passport' | '';
  documentExpirationDate: string | Date;
  documentCountry: string;
  name: string;
  lastName: string;
  gender: '' | 'male' | 'female';
  active: string;
  birthday: string | Date;
  allergy: string;
  observations: string;
  tripId: number;
  createdAt: string | Date;
  updatedAt: string | Date;

  constructor(
    {
      id = '',
      temporaryId = self.crypto.randomUUID(),
      document = '',
      documentType = '',
      documentExpirationDate = '',
      documentCountry = '',
      name = '',
      lastName = '',
      gender = '',
      birthday = '',
      allergy = '',
      observations = '',
      tripId,
      createdAt = new Date(),
      updatedAt = new Date(),
      active = true,
    }: Student = {} as Student
  ) {
    super();
    this.id = id;
    this.temporaryId = temporaryId;
    this.document = document ?? '';
    this.documentType = documentType ?? '';
    this.documentExpirationDate = documentExpirationDate
      ? dayjs(documentExpirationDate).format(config.APP.DATE.DATE_TYPE_FORMAT)
      : '';
    this.documentCountry = documentCountry ?? '';
    this.name = name ?? '';
    this.lastName = lastName ?? '';
    this.gender = gender ?? '';
    this.birthday = birthday ? dayjs(birthday).format(config.APP.DATE.DATE_TYPE_FORMAT) : '';
    this.allergy = allergy ?? '';
    this.observations = observations ?? '';
    this.tripId = tripId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.active = String(active ?? true);
  }

  static toPayload(student: Student) {
    const payload = Student.toJSON(student);

    for (const key in payload) {
      if (isBlank(payload[key])) {
        delete payload[key];
      }
    }

    return payload;
  }

  static toJSON(student: Student) {
    const json: Record<string, string> = {
      name: student.name,
      lastName: student.lastName,
      birthday: student.birthday ? dayjs(student.birthday).toISOString() : '',
      gender: student.gender,
      documentType: student.documentType,
      document: student.document,
      documentExpirationDate: student.documentExpirationDate
        ? dayjs(student.documentExpirationDate).toISOString()
        : '',
      documentCountry: student.documentCountry,
      allergy: student.allergy,
      observations: student.observations,
    };

    return json;
  }
}
