import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import PhCheckCircle from 'ember-phosphor-icons/components/ph-check-circle';
import PhWarning from 'ember-phosphor-icons/components/ph-warning';
import PhWarningCircle from 'ember-phosphor-icons/components/ph-warning-circle';
import type NotificationService from 'vfc-client/services/notification-service';
const CSS_CLASSES_BY_TYPE = {
    error: 'border-error-500 bg-error-50 text-error-500',
    warning: 'border-orange-500 bg-orange-50 text-orange-500',
    success: 'border-accent1-500 bg-accent1-50 text-accent1-500'
};
const ICON_BY_TYPE = {
    error: PhWarningCircle,
    warning: PhWarning,
    success: PhCheckCircle
};
export default class Notification extends Component<{
    Element: HTMLDivElement;
    Args: {
        notification: Notification;
    };
}> {
    @service
    notificationService: NotificationService;
    get notificationClassesByType() {
        return CSS_CLASSES_BY_TYPE[this.args.notification.type];
    }
    get notificationIconByType() {
        return ICON_BY_TYPE[this.args.notification.type];
    }
    static{
        template(`
    <div class="border {{this.notificationClassesByType}} p-2 rounded-md" ...attributes>
      <div class="flex justify-between gap-2 items-center">
        <div class="flex gap-2 items-center">
          <this.notificationIconByType class="w-5 h-5 text-current" />
          {{@notification.message}}
        </div>
        <button
          class="self-end"
          type="button"
          {{on "click" (fn this.notificationService.delete @notification)}}
        >
          <svg
            class="stroke-current"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
