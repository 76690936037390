import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type UserService from 'vfc-client/services/user-service';

export type MyProfileType = {
  username?: string;
  password?: string;
  email?: string;
  name?: string;
  phone?: string;
  createdAt?: string;
  updatedAt?: string;
  errors?: Array<unknown>;
};

export default class MyProfile extends Route {
  @service declare userService: UserService;
  async model() {
    await this.userService.loadUserProfile();
  }
}
