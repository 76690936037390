import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type { Session } from 'ember-simple-auth/services/session';

export default class RequestPassword extends Route {
  @service declare session: Session;
  async beforeModel() {
    this.session.prohibitAuthentication('authenticated');
  }
}
