import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Notification from 'vfc-client/components/notification';
import config from 'vfc-client/config/environment';
import type NotificationService from 'vfc-client/services/notification-service';
export default class NotificationContainer extends Component<{
    Element: HTMLDivElement;
}> {
    @service
    notificationService: NotificationService;
    setTimeout = (notification1: Notification)=>{
        notification1.timeoutId = self.setTimeout(()=>{
            this.clearTimeout(notification1);
            this.notificationService.delete(notification1);
        }, config.APP.NOTIFICATION_TIMEOUT);
    };
    clearTimeout = (notification1: Notification)=>{
        if (notification1.timeoutId) {
            clearTimeout(notification1.timeoutId);
            notification1.timeoutId = undefined;
        }
    };
    static{
        template(`
    <div ...attributes>
      {{#each this.notificationService.notifications as |notification|}}
        {{(this.setTimeout notification)}}
        <Notification
          {{on "mouseenter" (fn this.clearTimeout notification)}}
          {{on "mouseleave" (fn this.setTimeout notification)}}
          @notification={{notification}}
        />
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
