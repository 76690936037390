import { template } from "@ember/template-compiler";
import { service } from 'ember-primitives';
import Route from 'ember-route-template';
import Footer from 'vfc-client/components/footer';
import Header from 'vfc-client/components/header';
import NotificationContainer from 'vfc-client/components/ui/notification-container';
const isRouteWithoutHeaderFooter = (currentRoute1: string)=>{
    return [
        'login',
        'request-password'
    ].includes(currentRoute1);
};
export default Route(template(`
    <div class="flex flex-col h-full">
      {{#let (service "router") as |router|}}
        {{#unless (isRouteWithoutHeaderFooter router.currentRoute.name)}}
          <Header class="bg-white" />
        {{/unless}}
        <div class="flex justify-center flex-1 w-full bg-neutral-50">
          {{! optional left side navigation }}
          <div id="left-side-navigation"></div>
          <main class="flex flex-1 max-w-screen-xl">
            {{outlet}}
          </main>
        </div>
        {{#unless (isRouteWithoutHeaderFooter router.currentRoute.name)}}
          <Footer class="pt-4" />
        {{/unless}}
      {{/let}}
    </div>
    <NotificationContainer class="absolute z-50 top-10 right-0 p-5" />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
