import Helper from '@ember/component/helper';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

type Positional = Array<string>;

export interface UrlForRouteSignature {
  Args: {
    Positional: Positional;
  };
  Return: string;
}

class UrlForRoute extends Helper<UrlForRouteSignature> {
  @service declare router: RouterService;
  compute([url]: [string]) {
    return this.router.urlFor(url);
  }
}

export { UrlForRoute as urlForRoute };
