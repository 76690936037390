import { template } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
let PANEL_SEQUENCE = 0;
export default class Panel extends Component {
    @tracked
    panelId;
    setPanelRef = modifier((el1: HTMLFormElement)=>{
        this.panelId = isBlank(el1.id) ? String(PANEL_SEQUENCE) : el1.id;
        if (this.args.onInsertPanel) {
            this.args.onInsertPanel(this);
        }
        PANEL_SEQUENCE++;
    });
    @cached
    get nodeIndex() {
        return this.args.tabIds.indexOf(this.panelId);
    }
    @cached
    get selected() {
        return this.nodeIndex === this.args.selectedIndex;
    }
    get isActive() {
        return this === this.args.activePanel;
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <div
      class="{{if this.isActive 'block' 'hidden'}}"
      id={{this.panelId}}
      {{this.setPanelRef}}
      ...attributes
    >
      {{yield this.isActive}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
