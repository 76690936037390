import { assert } from '@ember/debug';
import { registerDestructor } from '@ember/destroyable';
import type Owner from '@ember/owner';
import Modifier, { type ArgsFor } from 'ember-modifier';

interface NamedParams {
  when: boolean;
}

export interface DisableSignature {
  Element: HTMLElement;
  Args: {
    Named: NamedParams;
  };
}

export default class Disable extends Modifier<DisableSignature> {
  declare element: HTMLElement;

  stopEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  constructor(owner: Owner, args: ArgsFor<DisableSignature>) {
    super(owner, args);
    registerDestructor(this, () => {
      this.element.removeEventListener('click', this.stopEvent);
    });
  }

  modify(element: HTMLElement, _: never[], { when: isDisabled }: NamedParams) {
    assert(
      'a11y-disabled: disabled modifier requires boolean `when` argument',
      typeof isDisabled === 'boolean'
    );

    this.element = element;
    if (isDisabled) {
      this.element.setAttribute('disabled', 'true');
    } else {
      this.element.removeAttribute('disabled');
    }
    this.element.setAttribute('aria-disabled', isDisabled ? 'true' : 'false');

    if (isDisabled) {
      this.element.addEventListener('click', this.stopEvent);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    disable: typeof Disable;
  }
}
