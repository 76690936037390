import { template } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
let TAB_SEQUENCE = 0;
export default class Tab extends Component {
    @tracked
    tabId;
    setTabRef = modifier((el1: HTMLFormElement)=>{
        this.tabId = isBlank(el1.id) ? String(TAB_SEQUENCE) : el1.id;
        if (this.args.onInsertTab) {
            this.args.onInsertTab(this);
        }
        TAB_SEQUENCE++;
    });
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    {{#if @isActive}}
      {{@onActiveTab this}}
    {{/if}}
    <li id={{this.tabId}} {{this.setTabRef}} {{on "click" @onClickTab}} ...attributes>
      {{yield @isActive}}
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
