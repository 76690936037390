export class ParentChildArray<T> extends Array<T> {
  declare iterableProperty: string;
  constructor() {
    super();
  }
  [Symbol.iterator]() {
    if (this.iterableProperty) {
    } else {
      return super[Symbol.iterator]();
    }
  }
}
