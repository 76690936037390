import { template } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import type { ComponentLike } from '@glint/template';
import { TrackedArray } from 'tracked-built-ins';
import PanelList from './tabs/panel-list';
import TabList from './tabs/tab-list';
export default class Tabs extends Component<{
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
        default: [];
    };
}> {
    tabs = new TrackedArray([]);
    panels = new TrackedArray([]);
    @tracked
    activeTab: Tab;
    @tracked
    activePanel: Panel;
    @action
    onInsertTab(tab1: Tab) {
        this.tabs.push(tab1);
    }
    @action
    onInsertPanel(panel1: Panel) {
        this.panels.push(panel1);
    }
    @action
    onActiveTab(tab1: Tab) {
        this.activeTab = tab1;
        this.activePanel = this.panels.find((panel1: Panel)=>panel1.panelId === tab1.tabId);
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <div ...attributes>
      {{yield
        (hash
          Tabs=(component
            TabList onInsertTab=this.onInsertTab onClickTab=@onClickTab onActiveTab=this.onActiveTab
          )
          Panels=(component PanelList onInsertPanel=this.onInsertPanel activePanel=this.activePanel)
        )
      }}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
