import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { modifier } from 'ember-modifier';
import { and } from 'ember-truth-helpers';
import { not, or } from 'ember-truth-helpers';
import { local } from 'embroider-css-modules';
import styles from './show-more.module.css';
export default class ShowMore extends Component<{
    Element: HTMLDivElement;
    Args: {
        fadeEffect?: boolean;
        maxHeight?: string;
    };
    Blocks: {
        default: [boolean];
    };
}> {
    @tracked
    isCollapsed = true;
    @tracked
    isOverflow = false;
    setupResizeObserver = modifier((element1: HTMLElement)=>{
        const resizeObserver1 = new ResizeObserver((entries1)=>{
            for (const entry1 of entries1){
                this.isOverflow = this.calculateOverflow(entry1.target);
            }
        });
        resizeObserver1.observe(element1);
    });
    @action
    toggle() {
        this.isCollapsed = !this.isCollapsed;
    }
    get maxHeight() {
        return this.args.maxHeight ? this.args.maxHeight : 'max-h-[45vw]';
    }
    calculateOverflow(element1: Element) {
        const isOverflow1 = element1.scrollHeight > element1.clientHeight;
        return isOverflow1;
    }
    static{
        template(`
    <div
      {{this.setupResizeObserver}}
      class="relative overflow-hidden {{if this.isCollapsed this.maxHeight}}"
    >
      {{yield this.isCollapsed}}
      {{#if (and @fadeEffect this.isCollapsed this.isOverflow)}}
        <div class="{{local styles 'fadeout-image'}} absolute bottom-0 bg-white h-1/5 w-full"></div>
      {{/if}}
    </div>
    {{#if (or this.isOverflow (not this.isCollapsed))}}
      <div>
        <button
          type="button"
          class="px-3 py-2 font-semibold rounded-full hover:bg-neutral-100"
          {{on "click" this.toggle}}
        >
          {{if
            this.isCollapsed
            (t "components.ui.show-more.show-more-label")
            (t "components.ui.show-more.show-less-label")
          }}
        </button>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
