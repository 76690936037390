import dayjs, { type OpUnitType, type QUnitType } from 'dayjs';
import config from 'vfc-client/config/environment';

export const formatDate = (date: Date | string, format?: string) =>
  dayjs(date).format(format ?? config.APP.DATE.DEFAULT_FORMAT);

export const diffDate = (
  dateA: Date | string,
  dateB: Date | string,
  unit: QUnitType | OpUnitType = 'milliseconds'
) => {
  return dayjs(dateA).diff(dayjs(dateB), unit);
};

export const isDateGreaterThan = (dateA: Date | string, dateB: Date | string) => {
  return dayjs(dateA).isAfter(dayjs(dateB));
};

export const isDateLessThan = (dateA: Date | string, dateB: Date | string) => {
  return dayjs(dateA).isBefore(dayjs(dateB));
};

export const timeFromNow = (date: Date | string) => dayjs(date).fromNow();
