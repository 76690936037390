import { template } from "@ember/template-compiler";
import './rooms-tab.css';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import type { Args } from '@glimmer/component/-private/component';
import { cached, tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { use } from 'ember-resources';
import { TrackedArray } from 'tracked-built-ins';
import RoomComponent from 'vfc-client/components/trip-details/room-tab/room';
import Loader from 'vfc-client/components/ui/loader';
import Tabs from 'vfc-client/components/ui/tabs';
import config from 'vfc-client/config/environment';
import ResponseError from 'vfc-client/models/response-error';
import Room from 'vfc-client/models/room';
import type UserService from 'vfc-client/services/user-service';
import type { Trip } from 'vfc-client/types/trip';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';
import { isDateLessThan } from 'vfc-client/utils/date-utils';
import { formatDate } from 'vfc-client/utils/date-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
type RoomResponse = {
    rooms: Array<Room>;
    disableRoomAvailability: Date | null;
    enableRoomAvailability: Date | null;
};
interface RoomsTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
        activeTab: string;
    };
    Blocks: {
        default: [];
    };
}
export default class RoomsTab extends Component<RoomsTabSignature> {
    @tracked
    activeTab = this.args.activeTab;
    @service
    userService: UserService;
    @use
    authenticatedRemoteData = AuthenticatedRemoteData<RoomResponse>(urlFor(`/trip/${this.args.trip.id}/rooms`));
    maleRooms: Array<Room> = new TrackedArray();
    femaleRooms: Array<Room> = new TrackedArray();
    mixedRooms: Array<Room> = new TrackedArray();
    constructor(owner1: Owner, args1: Args<RoomsTabSignature>){
        super(owner1, args1);
        this.authenticatedRemoteData.fetchPromise.then((response1)=>{
            response1.rooms.forEach((room1)=>{
                const roomInstance1 = new Room({
                    ...room1
                });
                if (roomInstance1.type === 'male') {
                    this.maleRooms.push(roomInstance1);
                } else if (roomInstance1.type === 'female') {
                    this.femaleRooms.push(roomInstance1);
                } else {
                    this.mixedRooms.push(roomInstance1);
                }
                this.activeTab = this.activeTab || this.defaultTab;
            });
        }).catch((error1: unknown)=>{
            if (error1 instanceof Response) {
                error1.json().then((json1)=>{
                    throw new ResponseError(error1.status, json1);
                });
            } else {
                throw error1;
            }
        });
    }
    isActiveTab = (tab1: string)=>{
        return tab1 === this.activeTab;
    };
    @cached
    get hasRooms() {
        return isPresent(this.femaleRooms) || isPresent(this.maleRooms) || isPresent(this.mixedRooms);
    }
    get defaultTab() {
        let tab1 = 'mix';
        if (isPresent(this.maleRooms)) {
            tab1 = 'male';
        } else if (isPresent(this.femaleRooms)) {
            tab1 = 'female';
        }
        return tab1;
    }
    get isEnableRoomAvailability() {
        if (!this.authenticatedRemoteData?.value?.enableRoomAvailability) return false;
        return isDateLessThan(this.authenticatedRemoteData.value.enableRoomAvailability, new Date());
    }
    get isDisableRoomAvailability() {
        if (!this.authenticatedRemoteData?.value?.disableRoomAvailability) return false;
        return isDateLessThan(this.authenticatedRemoteData.value.disableRoomAvailability, new Date());
    }
    @action
    setActiveTab(event1: Event) {
        event1.preventDefault();
        const target1 = event1.target;
        if (target1 instanceof HTMLElement) {
            const dataset1 = target1.dataset;
            if ('tab' in dataset1) {
                this.activeTab = dataset1['tab'] ?? this.defaultTab;
                sessionStorage.setItem(`${this.userService.username}.Rooms.activeTab`, this.activeTab);
            }
        }
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section id="rooms" class="w-full">
      <div class="rounded-3xl w-full bg-white border border-neutral-200 p-12">
        <div class="m-auto">
          {{#if this.authenticatedRemoteData.isResolved}}
            <div class="flex flex-col gap-1 pb-8">
              <h2>
                {{t "page.trip-details.rooms-tab.title"}}
              </h2>
              {{#if this.isEnableRoomAvailability}}
                <div>
                  Fecha límite para rellenar habitaciones:
                  {{#let
                    this.authenticatedRemoteData.value.disableRoomAvailability
                    as |disableRoomAvailability|
                  }}
                    {{#if (isPresent disableRoomAvailability)}}
                      <b
                        class="whitespace-nowrap underline underline-offset-2 decoration-accent1-300 decoration-2"
                      >
                        {{formatDate disableRoomAvailability config.APP.DATE.USER_FRIENDLY_FORMAT}}
                      </b>
                    {{/if}}
                  {{/let}}
                </div>
              {{else}}
                <div>
                  Las habitaciones no están disponibles
                  {{#if this.authenticatedRemoteData.value.enableRoomAvailability}}
                    hasta el
                    <b>
                      {{formatDate
                        this.authenticatedRemoteData.value.enableRoomAvailability
                        config.APP.DATE.USER_FRIENDLY_FORMAT
                      }}
                    </b>
                  {{/if}}
                </div>
              {{/if}}
            </div>
            {{#if this.isEnableRoomAvailability}}
              {{#if this.hasRooms}}
                <Tabs as |T|>
                  <T.Tabs
                    class="-mb-px flex items-center gap-4 text-sm font-medium pb-8 [&>*]:flex-1"
                    @onClickTab={{this.setActiveTab}}
                    as |Tab|
                  >
                    {{#if (isPresent this.maleRooms)}}
                      <Tab @isActive={{this.isActiveTab "male"}} as |active|>
                        <a
                          data-tab="male"
                          href="#"
                          class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                            {{if
                              active
                              'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-accent1-300'
                              'text-gray-500 hover:text-black'
                            }}"
                        >
                          Masculino
                        </a>
                      </Tab>
                    {{/if}}
                    {{#if (isPresent this.femaleRooms)}}
                      <Tab @isActive={{this.isActiveTab "female"}} as |active|>
                        <a
                          data-tab="female"
                          href="#"
                          class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                            {{if
                              active
                              'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-accent1-300'
                              'text-gray-500 hover:text-black'
                            }}"
                        >
                          Femenino
                        </a>
                      </Tab>
                    {{/if}}
                    {{#if (isPresent this.mixedRooms)}}
                      <Tab @isActive={{this.isActiveTab "mix"}} as |active|>
                        <a
                          data-tab="mix"
                          href="#"
                          class="relative flex items-center justify-center gap-2 px-1 py-3 font-semibold
                            {{if
                              active
                              'text-black after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-accent1-300'
                              'text-gray-500 hover:text-black'
                            }}"
                        >
                          Mixto
                        </a>
                      </Tab>
                    {{/if}}
                  </T.Tabs>
                  <T.Panels as |Panel|>
                    {{#if (isPresent this.maleRooms)}}
                      <Panel class="space-y-2" as |active|>
                        {{#if active}}
                          {{#each this.maleRooms as |room|}}
                            <RoomComponent
                              @tripId={{@trip.id}}
                              @room={{room}}
                              @disabled={{this.isDisableRoomAvailability}}
                            />
                          {{/each}}
                        {{/if}}
                      </Panel>
                    {{/if}}
                    {{#if (isPresent this.femaleRooms)}}
                      <Panel class="space-y-2" as |active|>
                        {{#if active}}
                          {{#each this.femaleRooms as |room|}}
                            <RoomComponent
                              @tripId={{@trip.id}}
                              @room={{room}}
                              @disabled={{this.isDisableRoomAvailability}}
                            />
                          {{/each}}
                        {{/if}}
                      </Panel>
                    {{/if}}
                    {{#if (isPresent this.mixedRooms)}}
                      <Panel class="space-y-2" as |active|>
                        {{#if active}}
                          {{#each this.mixedRooms as |room|}}
                            <RoomComponent
                              @tripId={{@trip.id}}
                              @room={{room}}
                              @disabled={{this.isDisableRoomAvailability}}
                            />
                          {{/each}}
                        {{/if}}
                      </Panel>
                    {{/if}}
                  </T.Panels>
                </Tabs>
              {{else}}
                No hay habitaciones disponibles
              {{/if}}
            {{/if}}
          {{else}}
            <div class="flex justify-center">
              {{! TODO: this second loader produce a weird effect, we need to fix it }}
              <Loader />
            </div>
          {{/if}}
        </div>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
