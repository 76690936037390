import { template } from "@ember/template-compiler";
import './trip-details-tab.css';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import PHDownload from 'ember-phosphor-icons/components/ph-download';
import PhFilePdf from 'ember-phosphor-icons/components/ph-file-pdf';
import PhWarning from 'ember-phosphor-icons/components/ph-warning';
import { eq } from 'ember-truth-helpers';
import { not } from 'ember-truth-helpers';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type { Trip } from 'vfc-client/types/trip';
import { downloadFile } from 'vfc-client/utils/file-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
export default class DocumentsTab extends Component<{
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
    };
    Blocks: {
        default: [];
    };
}> {
    @service('auth/http-client')
    authenticatedHttpClient: AuthenticatedHttpClient;
    @service
    router: RouterService;
    @tracked
    file?: File;
    @tracked
    isUploading: boolean = false;
    @tracked
    uploadError?: string;
    get hasContract() {
        return isPresent(this.args.trip.contract);
    }
    get hasContractSigned() {
        return this.hasContract && this.args.trip.contractState !== 'pending';
    }
    get isPendingContract() {
        return this.hasContract && !this.hasContractSigned;
    }
    get hasDocuments() {
        return (this.hasContract || isPresent(this.args.trip.summaryPdf) || isPresent(this.args.trip.proforma));
    }
    @action
    handleFileSelect(event1: Event) {
        const fileList1 = (event1.target as HTMLInputElement).files;
        if (fileList1 && fileList1.length > 0) {
            this.file = fileList1[0];
        }
    }
    @action
    async uploadPdfSummary() {
        if (!this.file) return;
        this.isUploading = true;
        const formData1 = new FormData();
        formData1.append('file', this.file);
        this.authenticatedHttpClient.POST<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/upload/contract`),
            body: formData1
        }).then(()=>{
            this.router.refresh();
        }).catch((response1: Response)=>{
            response1.json().then((data1)=>{
                this.uploadError = data1.message;
            });
        }).finally(()=>{
            this.isUploading = false;
        });
    }
    @action
    async downloadPDFSummary() {
        const response1 = await this.authenticatedHttpClient.GET<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/download/summary`)
        });
        downloadFile(response1, `resumen-viaje-${this.args.trip.reference}.pdf`);
    }
    @action
    async downloadContract() {
        const response1 = await this.authenticatedHttpClient.GET<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/download/contract`)
        });
        downloadFile(response1, `contrato-${this.args.trip.reference}.pdf`);
    }
    @action
    async downloadProforma() {
        const response1 = await this.authenticatedHttpClient.GET<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/download/proforma`)
        });
        downloadFile(response1, `proforma-${this.args.trip.reference}.pdf`);
    }
    static{
        template(`
    <section
      id="passengers"
      class="px-5 py-8 flex flex-col space-y-5 bg-white border border-neutral-200 rounded-3xl"
    >
      <h2>
        Mis documentos
      </h2>
      {{#if this.isPendingContract}}
        <div class="mt-4">
          <h5>
            <PhWarning class="inline-block fill-yellow-300" />
            Documentos pendientes de firmar
          </h5>
          <ul class="ms-3 mt-3">
            <li>
              <a
                class="text-md text-neutral-900 hover:text-accent1-400 cursor-pointer"
                {{on "click" this.downloadContract}}
              >
                Contracto
                <PHDownload class="inline-block" />
              </a>
              <div class="flex">
                <input
                  {{on "change" this.handleFileSelect}}
                  type="file"
                  id="file"
                  disabled={{this.isUploading}}
                  class="block border border-gray-200 rounded-md text-sm focus:z-10 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2.5 file:px-4 dark:file:bg-gray-700 dark:file:text-gray-400 cursor-pointer file:cursor-pointer"
                />
                {{#if this.file}}
                  <button
                    disabled={{this.isUploading}}
                    {{on "click" this.uploadPdfSummary}}
                    class="ms-3 rounded-md bg-accent1-400 hover:bg-accent1-500 text-white py-1 px-[0.625rem]"
                  >
                    Subir contrato
                  </button>
                {{/if}}
              </div>
              <small>
                Por favor suba el documento completo firmado
              </small>
              <p class=""></p>
            </li>
          </ul>
        </div>
      {{/if}}
      <div class="mt-4">
        <h5>
          <PhFilePdf class="inline-block" />
          Documentos del viaje
        </h5>
        <ul class="ms-3 mt-3">
          {{#if this.hasContractSigned}}
            <li>
              <button
                {{on "click" this.downloadContract}}
                class="rounded-full bg-neutral-100 text-neutral-900 hover:bg-neutral-200 py-1 px-[0.625rem]"
                type="button"
              >
                Contracto
                <PHDownload class="inline-block" />
              </button>
              {{#if (eq this.args.trip.contractState "signed")}}
                <span class="text-sm">
                  <span class="text-yellow-400 font-bold">
                    (Pendiente de aprobación)
                  </span>
                </span>
              {{else if (eq this.args.trip.contractState "rejected")}}
                <span class="text-red-400 font-bold">
                  (Su contrato no ha sido aprobado)
                </span>
              {{else if (eq this.args.trip.contractState "accepted")}}
                <span class="text-green-300 font-bold">
                  (Aprobado)
                </span>
              {{/if}}
            </li>
          {{/if}}
          {{#if (isPresent this.args.trip.summaryPdf)}}
            <li class="mt-3">
              <button
                {{on "click" this.downloadPDFSummary}}
                class="rounded-full bg-neutral-100 text-neutral-900 hover:bg-neutral-200 py-1 px-[0.625rem]"
                type="button"
              >
                Resumen del viaje
                <PHDownload class="inline-block" />
              </button>
            </li>
          {{/if}}
          {{#if (isPresent this.args.trip.proforma)}}
            <li class="mt-3">
              <button
                {{on "click" this.downloadProforma}}
                class="rounded-full bg-neutral-100 text-neutral-900 hover:bg-neutral-200 py-1 px-[0.625rem]"
                type="button"
              >
                Proforma
                <PHDownload class="inline-block" />
              </button>
            </li>
          {{/if}}
          {{#if (not this.hasDocuments)}}
            <p class="ms-3 mt-3">
              No hay documentos
            </p>
          {{/if}}
        </ul>
      </div>
      <div class="mt-4"></div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
