import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { HeadlessForm } from 'ember-headless-form';
import { t } from 'ember-intl';
import { service } from 'ember-primitives';
import Route from 'ember-route-template';
import { TrackedObject } from 'tracked-built-ins';
import { HeadlessFormWrapper } from 'vfc-client/components/headless-form-wrapper';
import type { MyProfileType } from 'vfc-client/routes/authenticated/my-profile';
import type NotificationService from 'vfc-client/services/notification-service';
import { Notification } from 'vfc-client/services/notification-service';
import type UserService from 'vfc-client/services/user-service';
import { concat } from 'vfc-client/utils/string-utils';
import { userInitials } from 'vfc-client/utils/user-utils';
let userService: UserService;
let notificationService: NotificationService;
let intl: Intl;
const setServices = (_userService1: UserService, _notificationService1: NotificationService, _intl1: Intl)=>{
    userService = _userService1;
    notificationService = _notificationService1;
    intl = _intl1;
};
const getUserProfile = ()=>userService.userProfile;
let errors = new TrackedObject({
    value: []
});
const validatePassword = ({ password: password1, confirmPassword: confirmPassword1 }: {
    password: string;
    confirmPassword: string;
    token: string;
})=>{
    if (password1 != confirmPassword1) {
        return {
            confirmPassword: [
                {
                    value: confirmPassword1,
                    type: 'emailConfirmationMatch',
                    message: 'Las contrasena no coinciden'
                }
            ]
        };
    }
    return true;
};
const handleSubmit = ({ name: name1, email: email1, phone: phone1, password: password1 }: MyProfileType)=>{
    userService.saveUserProfile({
        name: name1,
        email: email1,
        phone: phone1,
        password: password1
    }).then(()=>{
        notificationService.notify(new Notification({
            type: 'success',
            message: intl.t('page.my-profile.saved-successfully')
        }));
    }).catch((error1: unknown)=>{
        if (error1 instanceof Response) {
            error1.json().then((data1)=>{
                errors.value = data1.errors ?? [];
            });
        } else {
            throw error1;
        }
    });
};
export default Route<{
    Args: {
        model: {
            myProfile: MyProfileType;
        };
    };
}>(template(`
    {{! @glint-nocheck: not typesafe yet }}
    {{! TODO: investigate a better way to do it }}
    {{(setServices (service "user-service") (service "notification-service") (service "intl"))}}
    <section class="w-full px-8 py-16">
      <div class="rounded-xl w-full bg-white border border-gray-200 p-5">
        <span>
          {{t "page.my-profile.title"}}
        </span>
        {{#let (getUserProfile) as |userProfile|}}
          <HeadlessFormWrapper as |wrapper|>
            <HeadlessForm
              @data={{userProfile}}
              @validate={{validatePassword}}
              @validateOn="focusout"
              @revalidateOn="input"
              @onSubmit={{handleSubmit}}
              {{on "reset" wrapper.options.onResetFormData}}
              {{on "input" (fn wrapper.options.evaluateIfDataFormHasChanged userProfile)}}
              as |form|
            >
              <div class="flex flex-col justify-center items-center space-x-10 p-5">
                <div class="flex items-center gap-10">
                  <div class="flex flex-col items-center gap-y-5">
                    <div class="relative inline-block">
                      <div
                        class="flex items-center justify-center w-20 h-20 rounded-full bg-gradient-to-r from-accent2-200 to-accent2-400"
                      >
                        <span class="py-3 px-4 text-3xl uppercase">
                          {{userInitials userProfile.username}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <form.Field @name="username" as |field|>
                      <div>
                        <field.Label class="text-sm">
                          {{t "page.my-profile.fields.username"}}:
                        </field.Label>
                        <field.Input
                          required
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          disabled
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-sm text-red-500" />
                      </div>
                    </form.Field>
                    <form.Field @name="name" as |field|>
                      <div>
                        <field.Label class="text-sm">
                          {{t "page.my-profile.fields.name"}}:
                        </field.Label>
                        <field.Input
                          required
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-sm text-red-500" />
                      </div>
                    </form.Field>
                    <form.Field @name="email" as |field|>
                      <div>
                        <field.Label class="text-sm">
                          {{t "page.my-profile.fields.email"}}:
                        </field.Label>
                        <field.Input
                          required
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-sm text-red-500" />
                      </div>
                    </form.Field>
                    <form.Field @name="phone" as |field|>
                      <div>
                        <field.Label class="text-sm">
                          {{t "page.my-profile.fields.phone"}}:
                        </field.Label>
                        <field.Input
                          required
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-sm text-red-500" />
                      </div>
                    </form.Field>
                    <form.Field @name="password" as |field|>
                      <div>
                        <field.Label class="text-sm">
                          {{t "page.my-profile.fields.password"}}:
                        </field.Label>
                        <field.Input
                          type="password"
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-sm text-red-500" />
                        <small class="text-gray-500">
                          Dejar en blanco para no cambiar la contraseña
                        </small>
                      </div>
                    </form.Field>
                    <form.Field @name="confirmPassword" as |field|>
                      <div>
                        <field.Label class="block text-gray-700 text-sm font-bold mb-2">
                          Repetir contraseña
                        </field.Label>
                        <field.Input
                          type="password"
                          {{on "invalid" wrapper.options.validateInputNatively}}
                          class="{{if field.isInvalid 'border-red-500' 'focus:border-accent1-300'}}
                            h-6 text-sm w-full outline-none border-b disabled:cursor-not-allowed"
                        />
                        <field.Errors class="text-xs text-red-500" />
                      </div>
                    </form.Field>
                  </div>
                </div>
                <div class="flex justify-center gap-2 pt-5 col-span-1 md:col-span-2">
                  <button
                    type="submit"
                    class="bg-white text-neutral-900 border border-neutral-900 hover:bg-neutral-900 hover:text-white text-sm rounded-full px-3 py-2"
                  >
                    {{t "page.my-profile.save-button"}}
                  </button>
                  {{#if wrapper.options.hasFormDataChanged}}
                    <button
                      type="reset"
                      class="bg-white text-neutral-900 border border-neutral-900 hover:bg-neutral-900 hover:text-white text-sm rounded-full px-3 py-2"
                    >
                      {{t "page.my-profile.reset-button"}}
                    </button>
                  {{/if}}
                </div>
              </div>
              {{#if errors.value}}
                <div class="flex flex-col p-2 text-sm text-red-500">
                  <div>
                    {{t "common.error"}}
                  </div>
                  <div class="flex flex-col">
                    {{#each errors.value as |error|}}
                      {{#let (get error "path") (get error "message") as |path message|}}
                        <div>
                          <span>
                            - {{t (concat "common.form.validation.custom." message) field=path}}
                          </span>
                        </div>
                      {{/let}}
                    {{/each}}
                  </div>
                </div>
              {{/if}}
            </HeadlessForm>
          </HeadlessFormWrapper>
        {{/let}}
      </div>
    </section>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
