import { template } from "@ember/template-compiler";
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
export default class ButtonToggle extends Component<{
    Element: HtmlButtonElement;
    Args: {
        kind: 'primary | outline';
        size: 'small | xsmall';
        active: boolean;
        onClick?: (event: Event) => void;
    };
}> {
    static KIND = {
        primary: 'bg-neutral-100 text-neutral-900 hover:bg-neutral-200',
        'outline-active': 'bg-accent1-500 text-white',
        outline: 'bg-transparent border border-neutral-200 text-neutral-900 hover:bg-neutral-100',
        'primary-active': 'bg-accent1-500 text-white'
    };
    static SIZE = {
        small: 'py-2 px-[0.625rem]',
        xsmall: 'py-1 px-[0.625rem]'
    };
    get kind() {
        return (this.args.kind ?? 'primary') + (this.args.active ? '-active' : '');
    }
    get size() {
        return this.args.size ?? 'small';
    }
    @action
    onClick(event1: Event) {
        if (this.args.onClick) this.args.onClick(event1);
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <button
      type="button"
      class="rounded-full {{get ButtonToggle.KIND this.kind}} {{get ButtonToggle.SIZE this.size}}"
      {{on 'click' this.onClick}}
      ...attributes>
    {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
