import { registerDestructor } from '@ember/destroyable';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import type { IntlService } from 'ember-intl';
import Configuration from 'ember-simple-auth/configuration';
import type { Session } from 'ember-simple-auth/services/session';

export default class Application extends Route {
  @service declare session: Session;
  @service declare intl: IntlService;

  async beforeModel() {
    // Don't include MSW in production, only in DEBUG (tests, development)
    if (DEBUG) {
      await setupMSW(this);
    }
    Configuration.load({ routeAfterAuthentication: 'authenticated' });
    await this.session.setup();
    this.intl.setLocale(['es-es']);
  }
}

async function setupMSW(context: Application) {
  let { worker } = await import('/mocks/browser.ts');

  await worker.start();

  // Prevent duplication in tests,
  // where the app is setup and torn down a lot
  registerDestructor(context, () => worker.stop());
}
