import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Tab from './tab';
export default class TabList extends Component<{
    Element: HTMLUlElement;
    Args: {
    };
    Blocks: {
        default: [];
    };
}> {
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <ul ...attributes>
      {{yield
        (component Tab onInsertTab=@onInsertTab onClickTab=@onClickTab onActiveTab=@onActiveTab)
      }}
    </ul>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
