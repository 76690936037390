import type ApplicationInstance from '@ember/application/instance';
import type RouterService from '@ember/routing/router-service';
import { isEmpty } from '@ember/utils';
import Ember from 'ember';
import ResponseError from 'vfc-client/models/response-error';

const silentErrors = ['TransitionAborted'];
function isSilentError(error: Error) {
  return isEmpty(error.name) || silentErrors.includes(error.name);
}

export function initialize(applicationInstance: ApplicationInstance) {
  let routeService = applicationInstance.lookup('service:router') as RouterService;

  const sendError = (error: Error) => {
    // This check is for fixing console errors during tests
    if (!applicationInstance.isDestroyed) {
      if (error instanceof ResponseError) {
        if (error.status === 404) {
          routeService.transitionTo('not-found');
        }
      } else if (!isSilentError(error)) {
        throw error;
      }
    }
  };

  Ember.onerror = (error) => {
    sendError(error);
  };

  window.onerror = (message, source, lineno, colno, error) => {
    if (error instanceof Error) {
      sendError(error);
    }
    return true; //prevent the default action
  };

  window.onunhandledrejection = (event) => {
    if (event.reason instanceof Error) {
      sendError(event.reason);
      event.preventDefault();
    }
  };
}

export default {
  initialize,
};
