import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import formatMessage from 'ember-intl/helpers/format-message';
import { modifier } from 'ember-modifier';
import PhWarningCircle from 'ember-phosphor-icons/components/ph-warning-circle';
import type ModalDialog from 'ember-primitives/components/dialog';
import { Modal } from 'ember-primitives/components/dialog';
import ButtonText from 'vfc-client/components/ui/button/text';
let Summary = class Summary extends Component<{
    Element: HTMLElement;
    Args: {
        isOpen: boolean;
        beforeClosing: (event: Event) => void;
    };
    Blocks: {
        default: [];
    };
}> {
    @action
    handleBeforeClosingIfOpen(event1: Event) {
        if (this.args.isOpen) {
            this.args.beforeClosing(event1);
        }
    }
    static{
        template(`
    <summary
      ...attributes
      {{on "click" this.handleBeforeClosingIfOpen}}
    >
      {{yield}}
    </summary>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export class Accordion extends Component<{
    Element: HTMLElement;
    Blocks: {
        default: [{
                isOpen: boolean;
                Summary: WithBoundArgs<typeof Summary, 'isOpen' | 'beforeClosing'>;
            }];
    };
    Args: {
        closeIfPassCondition?: () => boolean;
    };
}> {
    @tracked
    open = false;
    detailsElement: HTMLDetailsElement;
    modal: ModalDialog;
    detailsRef = modifier((el1: HTMLDetailsElement)=>{
        this.detailsElement = el1;
    });
    @action
    toggle() {
        this.open = !this.open;
    }
    @action
    confirmBeforeClosing(event1: Event) {
        if (!this.open) return;
        if (this.args.closeIfPassCondition?.()) {
            this.modal.open();
            event1.preventDefault();
        }
    }
    @action
    closeDialogAndDetails(m1: ModalDialog) {
        m1.close();
        this.detailsElement.open = false;
    }
    @action
    registerModal(m1: ModalDialog) {
        this.modal = m1;
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <details {{this.detailsRef}} {{on "toggle" this.toggle}} ...attributes>
      {{#if @closeIfPassCondition}}
        <Modal as |m|>
          {{(this.registerModal m)}}
          <m.Dialog class="bg-white border border-neutral-200 rounded-lg w-[352px]">
              <form method="dialog">
                <main class="px-6 pt-4 pb-8 flex flex-col items-center">
                  <header class="w-full flex justify-end">
                    <button type="button" {{on "click" m.close}}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#0A1126" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </header>
                  {{!-- warning --}}
                  <div class="bg-error-50 w-9 h-9 flex items-center justify-center">
                    <PhWarningCircle class="fill-error-500 w-5 h-5" />
                  </div>
                  <h5 class="pt-4 pb-2 text-center">
                    {{formatMessage
                      "¿Estas seguro de que quieres continuar?"
                        htmlSafe=true
                    }}
                  </h5>
                  <div class="body3 text-center">
                    {{formatMessage
                      'Al continuar perderas todos los cambios realizados.'
                    }}
                  </div>
                </main>
                <hr class="h-px bg-neutral-400"/>
                <footer class="flex justify-center gap-2 py-4 px-4">
                  <ButtonText type="submit" @kind="outline" @size="small" class="w-full" >Cancelar</ButtonText>
                  <ButtonText type="button" @kind="primary-error" @size="small" class="w-full" {{on "click" (fn this.closeDialogAndDetails m)}}>Sí, continuar</ButtonText>
                </footer>
              </form>
          </m.Dialog>
        </Modal>
      {{/if}}

      {{yield
        (hash
          isOpen=this.open
          Summary=(component Summary isOpen=this.open beforeClosing=this.confirmBeforeClosing)
        )
      }}
    </details>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
