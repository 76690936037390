import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import PhMagnifyingGlass from 'ember-phosphor-icons/components/ph-magnifying-glass';
import { use } from 'ember-resources';
import Route from 'ember-route-template';
import RouteTemplate from 'ember-route-template';
import { and, gt, not } from 'ember-truth-helpers';
import { debounce } from 'reactiveweb/debounce';
import { TrackedArray, TrackedSet } from 'tracked-built-ins';
import { HeadlessFormWrapper } from 'vfc-client/components/headless-form-wrapper';
import { Accordion } from 'vfc-client/components/ui/accordion';
import ButtonToggle from 'vfc-client/components/ui/button/toggle';
import Loader from 'vfc-client/components/ui/loader';
import config from 'vfc-client/config/environment';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';
import { noAccentAndLowercase } from 'vfc-client/utils/string-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
let FaqsPage = class FaqsPage extends Component {
    @tracked
    _filterQuery = '';
    @tracked
    selectedTags: TrackedArray<string> = new TrackedArray([]);
    @use
    filterQuery = debounce(200, ()=>this._filterQuery);
    filterFaqs: TrackedArray<string> = new TrackedArray([]);
    @action
    setQuery(event1: Event) {
        this._filterQuery = event1.target.value;
    }
    @action
    includeExpectedTags(tags1: Array<string>) {
        if (this.selectedTags.length === 0) return true;
        return tags1.some((tag1)=>this.selectedTags.includes(tag1.name));
    }
    @action
    setfilterFaqs(faqs1: Array<unknown>) {
        const filteredFaqs1 = faqs1.filter((faq1)=>{
            const query1 = noAccentAndLowercase(this.filterQuery);
            return ((noAccentAndLowercase(faq1.title).includes(query1) || noAccentAndLowercase(faq1.description).includes(query1)) && this.includeExpectedTags(faq1.tags));
        });
        this.filterFaqs = new TrackedArray(filteredFaqs1);
        return this.filterFaqs;
    }
    getTags(filterFaqs1: Array<unknown>) {
        const tags1 = filterFaqs1.reduce((tags1, faq1)=>{
            faq1.tags.forEach((tag1)=>tags1.add(tag1.name));
            return tags1;
        }, new Set());
        return Array.from(tags1);
    }
    isSelectedTag = (tag1: string)=>{
        return this.selectedTags.includes(tag1);
    };
    @action
    toggleTag(tag1: string) {
        const index1 = this.selectedTags.indexOf(tag1);
        if (index1 !== -1) {
            this.selectedTags.splice(index1, 1);
        } else {
            this.selectedTags.push(tag1);
        }
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section class="w-full px-8 py-16">
      <div class="rounded-3xl w-full bg-white border border-neutral-200 p-12">
        <div class="m-auto">
          {{#let (AuthenticatedRemoteData (urlFor "/page/faqs")) as |requestedFaqs|}}
            {{#if requestedFaqs.isLoading}}
              <Loader />
            {{else}}
              {{#let (this.setfilterFaqs requestedFaqs.value.rows) as |filteredRequestedFaqs|}}
                <div class="pb-4 flex gap-1 items-center">
                  <PhMagnifyingGlass class="w-4 h-4" />
                  <input
                    class="block w-[21rem] text-sm border-b px-1 outline-none"
                    type="text"
                    placeholder="{{t 'page.help.input-placeholder'}}"
                    {{on "input" this.setQuery}}
                  />
                </div>
                {{#let (this.getTags requestedFaqs.value.rows) as |filteredTags|}}
                  {{#if (not (isEmpty filteredTags))}}
                    <div class="flex gap-2 py-2">
                      {{#each filteredTags as |tag|}}
                        <ButtonToggle
                          @size="xsmall"
                          @active={{this.isSelectedTag tag}}
                          {{on "click" (fn this.toggleTag tag)}}
                        >
                          {{tag}}
                        </ButtonToggle>
                      {{/each}}
                    </div>
                  {{/if}}
                  {{#if (and this.filterQuery (isEmpty filteredRequestedFaqs))}}
                    <div>
                      {{t "page.help.no-results"}}
                    </div>
                  {{/if}}
                  {{#each filteredRequestedFaqs as |faq index|}}
                    <Accordion class="{{if (gt index 0) 'py-6'}}" as |accordion|>
                      <accordion.Summary class="list-none group">
                        <div
                          class="flex items-center justify-between py-4 border-b border-neutral-200 cursor-pointer"
                        >
                          <h4>
                            {{faq.title}}
                          </h4>
                          {{! we CANNOT use a <ButtonIcon @kind=ghost @size=xsmall> because the button is not propagating the event to the summary element }}
                          <div
                            class="rounded-md bg-transparent text-neutral-900 hover:bg-neutral-100 w-6 h-6"
                          >
                            {{#if accordion.isOpen}}
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="#0A1126"
                                  stroke-width="1.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            {{else}}
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 5V19M5 12H19"
                                  stroke="#0A1126"
                                  stroke-width="1.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            {{/if}}
                          </div>
                        </div>
                      </accordion.Summary>
                      {{#if accordion.isOpen}}
                        <div class="py-6">
                          {{! details }}
                          {{htmlSafe faq.description}}
                        </div>
                      {{/if}}
                    </Accordion>
                  {{/each}}
                {{/let}}
              {{/let}}
            {{/if}}
          {{/let}}
        </div>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(FaqsPage);
