import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import t from 'ember-intl/helpers/t';
import { Modal } from 'ember-primitives';
import type ModalDialog from 'ember-primitives/components/dialog';
import Route from 'ember-route-template';
import { eq } from 'ember-truth-helpers';
import ButtonToggle from 'vfc-client/components/ui/button/toggle';
import Loader from 'vfc-client/components/ui/loader';
import PreventDefault from 'vfc-client/helpers/prevent-default';
import onScrollScreen from 'vfc-client/modifiers/on-scroll-screen';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type UserService from 'vfc-client/services/user-service';
import type { Trip } from 'vfc-client/types/trip';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';
import { queryElement } from 'vfc-client/utils/dom-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
import DocumentsTab from './documents-tab';
import PassengersTab from './passengers-tab';
import PaymentsTab from './payments-tab';
import RoomsTab from './rooms-tab';
import TripDetailsTab from './trip-details-tab';
let SideNav = class SideNav extends Component<{
    Element: HTMLElement;
    Args: {
        activeLink: string;
    };
    Blocks: {
    };
}> {
    static{
        template(`
    <nav
      class="flex flex-col items-center gap-2 bg-white bg-opacity-60 backdrop-blur-sm p-2 rounded-xl h-fit border border-gray-300 shadow-md"
      ...attributes
    >
      <a
        class="hover:bg-accent1-100 p-2 rounded-xl border hover:border-neutral-400
          {{if
            (eq @activeLink 'summary')
            'bg-accent1-200 border-neutral-900'
            'border-transparent'
          }}"
        href="#summary-anchor"
        {{! hack for @properLinks}}
        target="_self"
      >
        {{! details icon }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 12H21M3 6H21M9 18H21"
            stroke="#0A1126"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        class="hover:bg-accent1-100 p-2 rounded-xl border hover:border-neutral-400
          {{if
            (eq @activeLink 'accommodation')
            'bg-accent1-200 border-neutral-900'
            'border-transparent'
          }}"
        href="#accommodation-anchor"
        {{! hack for @properLinks}}
        target="_self"
      >
        {{! accomodation icon }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 14.2864C3.14864 15.1031 2 16.2412 2 17.5C2 19.9853 6.47715 22 12 22C17.5228 22 22 19.9853 22 17.5C22 16.2412 20.8514 15.1031 19 14.2864M18 8C18 12.0637 13.5 14 12 17C10.5 14 6 12.0637 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
            stroke="#0A1126"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        class="hover:bg-accent1-100 p-2 rounded-xl border hover:border-neutral-400
          {{if
            (eq @activeLink 'transport')
            'bg-accent1-200 border-neutral-900'
            'border-transparent'
          }}"
        href="#transport-anchor"
        {{! hack for @properLinks}}
        target="_self"
      >
        {{! -- departure icon }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.24999 20.25H15.75M1.58691 13.1596L5.12085 16.4727C5.5917 16.9141 6.19235 17.1919 6.83362 17.2648C7.47489 17.3378 8.12258 17.202 8.68054 16.8776L22.875 8.62499L21.1276 6.48924C20.6742 5.93517 20.0377 5.56147 19.3329 5.43565C18.6282 5.30983 17.9017 5.44018 17.2846 5.80315L13.125 8.24999L7.49999 6.37499L5.95321 7.0379C5.83749 7.08749 5.73612 7.16542 5.65843 7.2645C5.58075 7.36358 5.52926 7.48062 5.50871 7.60484C5.48816 7.72905 5.49921 7.85644 5.54084 7.97526C5.58247 8.09408 5.65335 8.2005 5.74693 8.28473L8.62499 10.875L5.99999 12.375L3.37499 11.25L1.80443 11.9231C1.68989 11.9722 1.58939 12.049 1.51201 12.1467C1.43463 12.2444 1.3828 12.3598 1.36121 12.4825C1.33962 12.6052 1.34895 12.7314 1.38836 12.8496C1.42776 12.9678 1.496 13.0744 1.58691 13.1596Z"
            stroke="#0A1126"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        class="hover:bg-accent1-100 p-2 rounded-xl border hover:border-neutral-400
          {{if (eq @activeLink 'extras') 'bg-accent1-200 border-neutral-900' 'border-transparent'}}"
        href="#extras-anchor"
        {{! hack for @properLinks}}
        target="_self"
      >
        {{! extras icon }}
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          color="#0A1126"
        >
          <rect width="256" height="256" fill="none" />
          <line
            x1="128"
            y1="128"
            x2="216"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
          <line
            x1="128"
            y1="64"
            x2="216"
            y2="64"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
          <line
            x1="128"
            y1="192"
            x2="216"
            y2="192"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
          <polyline
            points="40 64 56 80 88 48"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
          <polyline
            points="40 128 56 144 88 112"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
          <polyline
            points="40 192 56 208 88 176"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          />
        </svg>
      </a>
      <a
        class="hover:bg-accent1-100 p-2 rounded-xl border hover:border-neutral-400
          {{if
            (eq @activeLink 'activities')
            'bg-accent1-200 border-neutral-900'
            'border-transparent'
          }}"
        href="#activities-anchor"
        {{! hack for @properLinks}}
        target="_self"
      >
        {{! activities icon }}
        <svg
          width="24"
          height="24"
          data-slot="icon"
          fill="none"
          stroke-width="1.5"
          stroke="#0A1126"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          ></path>
        </svg>
      </a>
    </nav>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
/**
 * This tab component is may replaced by the route component when it is supported in Polaris
 */ interface TabSignature {
    Element: HTMLDivElement;
    Args: {
        tripId: string;
        activeTab: string;
    };
}
let Tab = class Tab extends Component<TabSignature> {
    @service
    router: RouterService;
    @service
    userService: UserService;
    @tracked
    activeTab = this.args.activeTab ?? 'trip-details';
    @tracked
    detailsTabAnchor = 'summary';
    @service('auth/http-client')
    authenticatedHttpClient: AuthenticatedHttpClient;
    @tracked
    isAcceptTermsDisabled: boolean = true;
    trip?: Trip;
    @action
    onScroll(data1: Event) {
        if (!this.isAcceptTermsDisabled) return;
        const element1 = data1.target as HTMLElement;
        this.isAcceptTermsDisabled = element1.scrollTop + element1.offsetHeight <= element1.scrollHeight - 50;
    }
    modal: ModalDialog;
    @action
    setActiveTab(event1: Event) {
        event1.preventDefault();
        const [target1] = event1.target instanceof HTMLSelectElement ? event1.target.selectedOptions : [
            event1.target
        ];
        if (target1 instanceof HTMLElement) {
            const dataset1 = target1.dataset;
            if ('tab' in dataset1) {
                this.activeTab = dataset1['tab'] ?? 'trip-details';
                this.router.transitionTo('authenticated.trip-details.index', this.args.tripId, {
                    queryParams: {
                        tab: this.activeTab
                    }
                });
            }
        }
        //reset hash
        const location1 = window.location.toString().replace(/#.+/, '');
        history.replaceState(null, '', location1);
    }
    isActiveTab = (tab1: string)=>{
        return tab1 === this.activeTab;
    };
    get activePassengersTab() {
        return (sessionStorage.getItem(`${this.userService.username}.Passengers.activeTab`) ?? 'students');
    }
    @action
    setDetailsTabAnchor(anchor1: string) {
        this.detailsTabAnchor = anchor1;
    }
    @action
    registerModal(m1: ModalDialog) {
        this.modal = m1;
    }
    @action
    openConditionsModal(trip1: Trip) {
        this.trip = trip1; // TODO Review id
        if (!trip1.acceptedDate) {
            this.modal.open();
        }
    }
    @action
    acceptedTerms() {
        this.authenticatedHttpClient.PUT<{
            id: string;
        }>({
            url: urlFor(`/trip/${this.trip.id}/accept-terms`)
        });
        this.modal.close();
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    {{#in-element (queryElement "#left-side-navigation")}}
      <div
        class="hidden sm:block sm:sticky top-0 pt-8 w-14
          {{if (this.isActiveTab 'trip-details') 'visible' 'invisible'}}"
      >
        <SideNav @activeLink={{this.detailsTabAnchor}} />
      </div>
    {{/in-element}}

    <div class="flex justify-center gap-2 m-auto">
      <div class="w-full">
        {{#in-element (queryElement "#header-submenu")}}
          <div class="pl-10">
            <div class="sm:hidden py-4">
              <label for="Tab" class="sr-only">
                Tab
              </label>

              <select
                class="w-full rounded-md border border-gray-300 py-2 px-4"
                {{on "change" this.setActiveTab}}
              >
                <option data-tab="trip-details">
                  Datos del viaje
                </option>
                <option data-tab="passengers">
                  Pasajeros
                </option>
                <option data-tab="payments">
                  Pagos
                </option>
                <option data-tab="rooms" select>
                  Habitaciones
                </option>
                <option data-tab="documents">
                  Documentos
                </option>
              </select>
            </div>

            <div class="hidden sm:block py-4">
              <div class="border-gray-200">
                <nav {{on "click" this.setActiveTab}}>
                  <ul class="flex gap-2 items-center">
                    <li class="px-1">
                      <ButtonToggle
                        data-tab="trip-details"
                        @kind={{"primary"}}
                        @size={{"xsmall"}}
                        @active={{(this.isActiveTab "trip-details")}}
                      >
                        {{t "page.trip-details.menu.trip-details"}}
                      </ButtonToggle>
                    </li>
                    <li class="px-1">
                      <ButtonToggle
                        data-tab="passengers"
                        @kind={{"primary"}}
                        @size={{"xsmall"}}
                        @active={{(this.isActiveTab "passengers")}}
                      >
                        {{t "page.trip-details.menu.passengers"}}
                      </ButtonToggle>
                    </li>
                    <li class="px-1">
                      <ButtonToggle
                        data-tab="payments"
                        @kind={{"primary"}}
                        @size={{"xsmall"}}
                        @active={{(this.isActiveTab "payments")}}
                      >
                        {{t "page.trip-details.menu.payments"}}
                      </ButtonToggle>
                    </li>
                    <li class="px-1">
                      <ButtonToggle
                        data-tab="rooms"
                        @kind={{"primary"}}
                        @size={{"xsmall"}}
                        @active={{(this.isActiveTab "rooms")}}
                      >
                        {{t "page.trip-details.menu.rooms"}}
                      </ButtonToggle>
                    </li>
                    <li class="px-1">
                      <ButtonToggle
                        data-tab="documents"
                        @kind={{"primary"}}
                        @size={{"xsmall"}}
                        @active={{(this.isActiveTab "documents")}}
                      >
                        {{t "page.trip-details.menu.documents"}}
                      </ButtonToggle>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        {{/in-element}}

        {{#let (AuthenticatedRemoteData (urlFor (concat "/trip/" @tripId))) as |tripDetails|}}
          {{#if tripDetails.isLoading}}
            <Loader />
          {{else if tripDetails.isError}}
            <div class="flex flex-1 items-center justify-center">
              <div class="w-full max-w-xs">
                <div class="grid grid-cols-2">
                  <div class="flex flex-col items-center">
                    <div class="text-7xl font-bold">
                      404
                    </div>
                    <div class="text-lg">
                      <div>
                        opps..
                      </div>
                      <div class="whitespace-nowrap">
                        {{t "page.trip-details.not-found"}}
                      </div>
                    </div>
                  </div>
                  <img src="/images/plane-crash.png" />
                </div>
              </div>
            </div>
          {{else}}
            {{this.openConditionsModal tripDetails.value}}
            {{! Tabs content }}
            <div data-tab-content="" class="min-h-80">
              <div
                class="{{if
                    (this.isActiveTab 'trip-details')
                    'block opacity-100'
                    'hidden opacity-0'
                  }}"
                role="tabpanel"
              >
                <p
                  class="block font-sans text-base antialiased font-light leading-relaxed text-inherit text-blue-gray-500"
                >
                  <TripDetailsTab
                    @trip={{tripDetails.value}}
                    @setDetailsTabAnchor={{this.setDetailsTabAnchor}}
                  />
                </p>
              </div>
              <div
                class="{{if
                    (this.isActiveTab 'passengers')
                    'block opacity-100'
                    'hidden opacity-0'
                  }}"
                role="tabpanel"
              >
                <p
                  class="block font-sans text-base antialiased font-light leading-relaxed text-inherit text-blue-gray-500"
                >
                  <PassengersTab
                    @trip={{tripDetails.value}}
                    @activeTab={{this.activePassengersTab}}
                  />
                </p>
              </div>
              <div
                class="{{if (this.isActiveTab 'payments') 'block opacity-100' 'hidden opacity-0'}}"
                role="tabpanel"
              >
                <p
                  class="block font-sans text-base antialiased font-light leading-relaxed text-inherit text-blue-gray-500"
                >
                  <PaymentsTab @trip={{tripDetails.value}} />
                </p>
              </div>
              <div
                class="{{if (this.isActiveTab 'rooms') 'block opacity-100' 'hidden opacity-0'}}"
                role="tabpanel"
              >
                <p
                  class="block font-sans text-base antialiased font-light leading-relaxed text-inherit text-blue-gray-500"
                >
                  <RoomsTab @trip={{tripDetails.value}} />
                </p>
              </div>
              <div
                class="{{if (this.isActiveTab 'documents') 'block opacity-100' 'hidden opacity-0'}}"
                role="tabpanel"
              >
                <p
                  class="block font-sans text-base antialiased font-light leading-relaxed text-inherit text-blue-gray-500"
                >
                  <DocumentsTab @trip={{tripDetails.value}} />
                </p>
              </div>
            </div>
          {{/if}}
        {{/let}}
      </div>
    </div>
    <Modal @onClose={{this.handleClose}} as |m|>
      {{(this.registerModal m)}}
      <m.Dialog
        class="rounded-lg p-5 min-w-[min(750px,90%)] max-w-[min(750px,80%)]"
        {{on "click" (PreventDefault)}}
      >
        <div class="rounded-full bg-white">
          <header class="flex flex-col items-center justify-center mb-4">
            <svg
              class="fill-accent1-500"
              width="64"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 416.979 416.979"
              xml:space="preserve"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
                  ></path>
                </g>
              </g>
            </svg>
          </header>
          <div
            class="py-5 md:px-5 flex flex-col space-y-2 overflow-y-scroll max-h-[60vh]"
            {{onScrollScreen this.onScroll}}
          >
            <h3 class="text-4xl font-bold text-neutral-900 text-center">
              Hola
            </h3>
            <p class="text-justify text-md text-gray-500">
              Muchas gracias por reservar tu viaje con nosotros. Estamos muy contentos de poder
              ayudarte a gestionarlo. Ahora podrás gestionar todo lo relativo al viaje. Recuerda que
              debes rellenar al menos los DNIs de los pasajeros para darlos de alta. Con cualquier
              duda ponte en contacto con nosotros y como siempre te la resolvemos al momento.
            </p>
            <p class="text-justify text-md text-gray-500">
              Solo falta que mires y aceptes los términos y condiciones:
            </p>
            <div class="space-y-2">
              <p class="text-justify text-md text-gray-500">
                Las presentes Condiciones Generales de Contratación se rigen por lo dispuesto en el
                Real Decreto Legislativo 1/2007, de 16 de noviembre, de la Ley General para la
                Defensa de los Consumidores y Usuarios y otras leyes complementarias, modificado por
                el Real Decreto-ley 23/2018, de 21 de diciembre, de trasposición de directivas en
                materia de marcas, transporte ferroviario y viajes combinados y servicios de viajes
                vinculados, por la Ley 7/1998, de 13 de abril, sobre condiciones generales de
                contratación y por lo dispuesto a continuación.
              </p>
              <p class="text-justify text-md font-bold">
                INFORMACIÓN GENERAL
              </p>
              <p class="text-justify text-md text-gray-500">
                Titular: VYE SERVICIOS EDUCATIVOS 2009 SL, con nombre comercial
                Findecursocolegio.com, con CIF: B90090473 con domicilio fiscal y en Calle Puerta de
                la Carne 8, 1º Dcha, 41004, Sevilla, inscrito en el Registro Mercantil de Sevilla,
                folio 122, tomo 5.777 Sección General de sociedades, Hoja SE99333 con Código de
                Identificación Andaluz de Agencias de Viajes (CIAN) 416796-Z ; correo electrónico:
                info@findecursocolegio.com y teléfono contacto: +34955340204
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  ÁMBITO GENERAL
                </strong>
                <br />
                Cualquier contratación con Findecursocolegio.com está sometida a las presentes
                condiciones generales de venta que a continuación se publicitan libremente, con el
                fin de que cada CLIENTE pueda conocer y verificar la compra y los términos
                específicos.
                <br />
                Las CONDICIONES GENERALES tienen por objeto regular los términos contractuales para
                la prestación de los SERVICIOS por parte de Findecursocolegio.com, y, en su caso, la
                contraprestación debida por el CLIENTE a Findecursocolegio.com, por la utilización
                por aquél de determinados SERVICIOS.
              </p>
              <p class="text-justify text-md text-gray-500">
                La prestación de los SERVICIOS se llevará a cabo a mediante la obtención,
                disposición, organización, empleo y gestión por parte de Findecursocolegio.com de
                los recursos técnicos, humanos y operativos necesarios al efecto y, siempre y en
                todo caso, como contraprestación a los precios vigentes en cada momento cuando los
                SERVICIOS sean de pago. La mera navegación por la web www.findecursocolegio.com no
                tendrá la consideración de SERVICIO.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  Findecursocolegio.com
                </strong>
                es una agencia especializada en proveer servicios de viaje para grupos, ya sean
                institutos o empresas. Son estos los Clientes de la agencia quienes designarán un
                representante legal o mandatario para la realización y gestión del viaje, con
                independencia del número de viajeros que integren el grupo. A tal efecto, con la
                aceptación de las presentes condiciones generales, el Cliente declara expresamente
                que actúa como interlocutor válido entre la agencia y el resto de viajeros a los que
                representa, siendo el receptor de toda la
                <br />
                información que le suministre Findecursocolegio.com sobre el viaje y sus posibles
                modificaciones, no siendo responsable Findecursocolegio.com de cualesquiera
                consecuencias derivadas de la falta de información del resto de integrantes del
                viaje, siempre y cuando dicha información haya sido suministrada previamente al
                Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                Asimismo, será el Cliente responsable de trasladar cualquier tipo de incidencia que
                pueda surgir durante la ejecución del viaje, la solicitud de asistencia en el
                transcurso del mismo, o cualquier solicitud de cambio o modificación que quiera
                proponer. Findecursocolegio.com no será responsable ni administrativa, ni
                fiscalmente, de los importes o comisiones que el Cliente pueda cobrar a los viajeros
                por sus gestiones con la agencia de viajes; Findecursocolegio.com se reserva el
                derecho de repetir cualquier importe que se vea obligada a pagar como consecuencia
                de ello.
              </p>
              <p class="text-justify text-md text-gray-500">
                La combinación de servicios de viaje que se le ofrece por parte de
                Findecursocolegio.com un viaje combinado en el sentido del texto refundido de la Ley
                General para la Defensa de los Consumidores y Usuarios y otras leyes
                complementarias, aprobado por Real Decreto Legislativo 1/2007, de 16 de noviembre.
                Por lo tanto, usted gozará de todos los derechos que se aplican en el marco de la
                Unión Europea a los viajes combinados. Findecursocolegio.com es plenamente
                responsable de la correcta ejecución del viaje combinado en su conjunto en los
                términos previstos en la legislación vigente.
              </p>
              <p class="text-justify text-md text-gray-500">
                Además, como exige la legislación, Findecursocolegio.com está cubierta por una
                garantía para reembolsarle los pagos realizados y, si el transporte está incluido en
                el viaje, asegurar su repatriación en caso de que incurra(n) en insolvencia.
                Principales derechos en virtud del texto refundido de la Ley General para la Defensa
                de los Consumidores y Usuarios y otras leyes complementarias, aprobado por Real
                Decreto Legislativo 1/2007, de 16 de noviembre:
                <br />
                El Cliente recibirá toda la información esencial sobre el viaje combinado antes de
                celebrar el contrato de viaje combinado. Siempre habrá como mínimo un empresario
                responsable de la correcta ejecución de todos los servicios de viaje incluidos en el
                contrato. Se proporcionará al Cliente un número de teléfono de emergencia o los
                datos de un punto de contacto donde puedan contactar con la Agencia.
              </p>
              <p class="text-justify text-md text-gray-500">
                Los viajeros podrán ceder el viaje combinado a otra persona, con las mismas
                características y con un preaviso razonable y, en su caso, con sujeción al pago de
                gastos adicionales que conlleve los gastos de gestión. En cualquier caso, la
                tramitación de la cesión deberá realizarse por el Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                El precio del viaje combinado solo se podrá aumentar si se producen gastos
                específicos (por ejemplo, en los precios de combustible) y está expresamente
                estipulado en el contrato, y en ningún caso en los últimos veinte días anteriores al
                inicio del viaje combinado. Si el aumento de precio excede del ocho por ciento del
                precio del viaje combinado, el Cliente podrá poner fin al contrato. Si la agencia se
                reserva el derecho de aumentar el precio, el Cliente tendrá derecho a una reducción
                del precio si disminuyen los gastos correspondientes.
                <br />
                El Cliente podrá poner fin al contrato sin pagar ninguna penalización y obtener el
                reembolso completo de todos los pagos realizados si se modifica significativamente
                alguno de los elementos esenciales del viaje combinado que no sea el precio. Si el
                empresario responsable del viaje combinado lo cancela antes de su inicio, el Cliente
                tendrá derecho al reembolso de los pagos realizados y, cuando proceda, a una
                compensación.
              </p>
              <p class="text-justify text-md text-gray-500">
                En circunstancias excepcionales, por ejemplo, en caso de que en el lugar de destino
                existan graves problemas de seguridad que puedan afectar al viaje combinado, el
                Cliente podrá poner fin al contrato antes del inicio del viaje combinado, sin pagar
                ninguna penalización. Además, el Cliente podrá poner fin al contrato en cualquier
                momento antes del inicio del viaje combinado mediante el pago de una penalización
                por terminación en los términos determinados en el contrato, y ello con
                independencia de los gastos de gestión que resulten aplicables.
                <br />
                Si, después del inicio del viaje combinado, no pueden prestarse elementos
                significativos del mismo, deberán ofrecerse al Cliente fórmulas alternativas
                adecuadas, sin coste adicional. El Cliente podrá poner fin al contrato sin pagar
                ninguna penalización en caso de no ejecución de los servicios cuando ello afecte
                sustancialmente a la ejecución del viaje combinado y la agencia no consiga
                solucionar el problema.
              </p>
              <p class="text-justify text-md text-gray-500">
                El Cliente también tendrá derecho a una reducción del precio y/o a una indemnización
                por daños y perjuicios en caso de no ejecución o
                <br />
                ejecución incorrecta de los servicios de viaje.
              </p>
              <p class="text-justify text-md text-gray-500">
                La agencia deberá proporcionar asistencia al Cliente en caso de que este se
                encuentre en dificultades.
                <br />
                Si la agencia incurre en insolvencia se procederá al reembolso de los pagos. En caso
                de que la agencia incurra en insolvencia después del inicio del viaje combinado y
                este incluya el transporte, se garantizará la repatriación de los viajeros
                Findecursocolegio.com ha suscrito una garantía de protección frente a la insolvencia
                con AXA Seguros Generales. Si se deniegan servicios debido a la insolvencia de
                Findecursocolegio.com, los viajeros podrán ponerse en contacto con dicha entidad o,
                en su caso, con la autoridad competente (CIF A60917978. D.S.: calle Monseñor Palmer,
                1, 07014 – Palma de Mallorca, www.axa.es)
                <br />
                Findecursocolegio.com vela y protege especialmente al Cliente en los términos que se
                detallan en las presentes condiciones generales, así
                <br />
                como en el aviso legal y política de privacidad por medio de:
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  DEFINICIONES
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    1. Viaje combinado
                  </em>
                </strong>
                : es aquel en el que se combinan, al menos, dos servicios de viaje a efectos del
                mismo viaje o vacación, siempre que la combinación se formalice en un único contrato
                con independencia de que lo solicite o no el Cliente. También se considera viaje
                combinado aquel que, a pesar de formalizarse en varios contratos, se realice en uno
                de nuestros puntos de venta.
                <br />
                Se seleccione la combinación de servicios de viaje antes de que el Cliente consienta
                pagar. Se ofrezca, se venda o se facture por la agencia a un precio a tanto alzado o
                global. Se anuncie por la agencia como “viaje combinado”. Dichos contratos faculten
                al Cliente a elegir entre una selección de distintos servicios de viaje. Por último,
                también son contratos de viaje combinado, aquellos contratos formalizados en la web
                de Findecursocolegio.com (a través de un proceso de reserva en línea conectado), en
                el que el Cliente celebra un único contrato con el empresario, pero varios con los
                prestadores de los servicios contratados por el Cliente a más tardar dentro de las
                24 horas siguientes a la confirmación de la última reserva. También se considera
                viaje combinado aquel que combine un servicio de viaje (transporte, alojamiento y
                alquiler de vehículo) y adicionalmente, uno o más servicios de turísticos, siempre
                que: Los servicios turísticos representen una proporción igual o superior al 25% del
                valor de la combinación y se anuncien como una característica esencial de la
                combinación o; Si los servicios turísticos solo han sido contratados después de que
                se haya iniciado la ejecución de 1 servicio de viaje (transporte, alojamiento y
                alquiler de vehículo).
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    2. Servicio de viaje
                  </em>
                </strong>
                : Son servicios de viaje, el transporte de pasajeros, el alojamiento cuando no sea
                parte integrante del transporte de pasajeros y no tenga un fin residencial, el
                alquiler de turismos (Real Decreto 750/2010, de 4 de junio) y, cualquier otro
                servicio turístico que no forme parte integrante de un servicio de viaje de los
                citados anteriormente.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    3. Contrato de Viaje Combinado
                  </em>
                </strong>
                : es aquel que se formaliza en un solo contrato por el total del conjunto del viaje
                o, la formalización de varios contratos por cada uno de los servicios de viaje
                incluidos en el viaje combinado ofertado.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    4. Fecha de inicio del viaje combinado
                  </em>
                </strong>
                : es aquel día en el que comienza la ejecución de los servicios de viaje incluidos
                en el contrato.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    5. Repatriación
                  </em>
                  :
                </strong>
                el regreso del viajero al lugar de salida o a cualquier otro lugar acordado por las
                partes contratantes.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    6. Falta de conformidad
                  </em>
                </strong>
                : la no ejecución o la ejecución incorrecta de los servicios de viaje incluidos en
                un viaje combinado.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    7. Cliente
                  </em>
                  :
                </strong>
                toda persona jurídica, a través de su representante o mandatario, como instituto o
                sociedad mercantil que tiene intención de celebrar un contrato para organizar un
                viaje combinado.
              </p>
              <p class="text-justify text-md text-gray-500">
                &nbsp;
                <em>
                  <strong class="font-bold">
                    8. Viajero
                  </strong>
                  :
                </em>
                toda persona física integrante del grupo correspondiente al viaje contratado por el
                Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    9. Organizado
                  </em>
                  r
                </strong>
                : se considera a aquel empresario que combina y vende u oferta viajes combinados
                directamente, a través de o junto con
                <br />
                otro empresario.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    10. Minorista
                  </em>
                </strong>
                : aquel empresario distinto del organizador que vende u oferta los viajes combinados
                por un organizador.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    11. Falta de conformidad
                  </em>
                </strong>
                : la no ejecución o la ejecución incorrecta de los servicios de viaje incluidos en
                un viaje combinado.
              </p>
              <p class="text-justify text-md text-gray-500">
                <em>
                  <strong class="font-bold">
                    12. Menor
                  </strong>
                  :
                </em>
                toda persona menor de dieciocho años.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    13. Circunstancias inevitables y extraordinarias
                  </em>
                </strong>
                : se considerarán aquellas situaciones que están fuera de control de la parte que
                alega la
                <br />
                situación y cuyas consecuencias no habrían podido evitarse incluso si se hubieran
                adoptado todas las medidas razonables.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    14. Punto de venta
                  </em>
                </strong>
                : hace referencia a toda instalación de venta al por menor, tanto muebles como
                inmueble, o a un sitio web de venta al por menor o a un dispositivo similar de venta
                minorista en línea, incluso cuando estos sitios web o dispositivos se presenten al
                Cliente como un dispositivo único, incluido un servicio telefónico.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    15. Establecimiento:
                  </em>
                </strong>
                el acceso a una actividad económica no asalariada y su ejercicio, así como la
                constitución y gestión de empresas y especialmente de sociedades, en las condiciones
                fijadas por la legislación, por una duración indeterminada, en particular por medio
                de una infraestructura estable.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    16. Información precontractual
                  </em>
                  :
                </strong>
                Se entiende por información precontractual, aquella que el organizador o, en su
                caso, el minorista está obligado a facilitar al Cliente antes de que el Cliente
                quede obligado por cualquier contrato u oferta.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    17. Paquete tierra
                  </em>
                  :
                </strong>
                Se entiende por los servicios del viaje combinado como alojamiento, comidas,
                actividades, visitas, transporte terrestre y todo aquello que no comprenda el
                paquete aéreo.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  <em>
                    18. Paquete aéreo:
                  </em>
                </strong>
                Se entiende por el coste de los billetes de avión y todos los servicios asociados a
                los mismos (facturación, maletas, tarjetas de embarque, comisiones bancarias que
                cobre la aerolínea por pago de los vuelos, 6€ de comisión por la gestión del vuelo,
                coste de elección de asiento, cambios de nombre, y cualquier cargo extra que realice
                la aerolínea).
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  NOTAS IMPORTANTES DE VIAJES COMBINADOS
                </strong>
                <br />
                La información contenida en las presentes Condiciones Generales de viajes combinados
                y aquellas que se facilitan en el momento precontractual, tienen carácter genérico.
                No son de esperar variaciones significativas en el contenido y serán modificadas
                exclusivamente en los términos que se recojan en las Condiciones Particulares y en
                determinados supuestos que se exponen más adelante.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  1. ORGANIZACIÓN DEL VIAJE COMBINADO
                </strong>
                <br />
                La organización de los viajes combinados se realiza por Findecursocolegio.com.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  2. EL CONTRATO DE VIAJE COMBINADO
                </strong>
                <br />
                Findecursocolegio.com se compromete a entregar al Cliente una copia del contrato de
                viaje combinado que formalicen las partes, a más tardar en el plazo de 24 horas a
                contar desde dicha formalización. No obstante, el contrato estará a disposición del
                Cliente en formato pdf en su intranet. Formarán parte integrante del contrato de
                viaje combinado, todas las condiciones que regulan el viaje combinado contratado, en
                concreto, las condiciones generales dispuestas en este texto, las condiciones
                precontractuales, y las condiciones particulares pactadas entre la agencia y el
                Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  3. PRECIO
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                <em>
                  1. El precio del viaje combinado incluye:
                </em>
                <br />
                El precio de todos los servicios turísticos contratados (transporte de pasajeros,
                alojamiento, alquiler de vehículos de motor, etc.), con independencia de si se ha
                formalizado en un contrato o en varios contratos; Las comisiones, recargos y costes
                adicionales; La prestación de asistencia por parte de LA AGENCIA si el Cliente se
                halla en dificultades, especialmente, en supuestos de
                <br />
                circunstancias extraordinarias e inevitables y las dispuestas en la normativa de
                aplicación; La posibilidad de solicitar asistencia cuando tenga dificultades para
                presentar una reclamación por cualquier falta de conformidad del Cliente durante el
                viaje.
              </p>
              <p class="text-justify text-md text-gray-500">
                <em>
                  2. El precio del viaje combinado no incluye:
                </em>
                <br />
                Cualquier otro servicio no especificado concretamente en el contrato, tales como:
                Visados «extras» tales como: cafés, vinos, licores, aguas minerales, regímenes
                alimenticios especiales, lavado y planchado de ropa, parking, utilización del
                teléfono, cunas, alquiler de TV, servicios de Spa, balnearios, termas, masajes,
                tratamientos médicos, terapéuticos o de salud y; cualquier otro servicio similar que
                el establecimiento ofrezca por un precio independiente al del servicio principal
                contratado.
                <br />
                Aquellos impuestos adicionales en determinados destinos, que pueden variar en
                función de la categoría del establecimiento y del destino. Dichas tasas se pagarán
                directamente en el establecimiento hotelero y será el Cliente el único responsable
                de pagar los mismos. Tributos/tasas de entrada/salida aeropuertos en los que estén
                vigentes.
              </p>
              <p class="text-justify text-md text-gray-500">
                <em>
                  3. Modificaciones del precio:
                </em>
                <br />
                El precio del viaje combinado ha sido calculado según los tipos de cambio, tarifas
                de transporte y coste del carburante. Debido a la constante subida del petróleo o en
                otras fuentes de energía, en ocasiones, el precio del viaje podrá ser modificado
                posteriormente a la contratación y confirmación del viaje, por la agencia siempre
                que dicha facultad se disponga en el contrato
                <br />
                formalizado. Dependiendo del destino se impondrán al Cliente, como parte integrante
                del precio total del viaje combinado, las tasas, impuestos y recargos turísticos de
                aterrizaje, embarque o desembarque en puertos o aeropuertos, tal y como se ha
                especificado en el apartado anterior. Dichos importes podrán ser modificados por
                aquellos terceros que no están involucrados directamente en la ejecución del viaje
                combinado y, como consecuencia de ello, podrán ser modificados por la agencia. En
                relación con la Tasa Turística, es una tasa que aplican en determinadas regiones y
                países los establecimientos hoteleros. El Cliente debe consultar si existe dicha
                tasa según su destino. Como consecuencia de cambios en el tipo de divisa aplicables
                al viaje combinado, la agencia podrá modificar el precio del viaje.
                <br />
                * Toda modificación de precios que se produzca como consecuencia de cualquiera de
                las razones expresadas anteriormente, será notificada por la agencia al Cliente con
                la justificación del incremento y su cálculo en soporte duradero, a más tardar
                veinte días naturales antes de la fecha de inicio del viaje combinado. Siempre que
                el contrato de viaje disponga la facultad la agencia de modificar el precio, como es
                el caso, el Cliente tendrá derecho a una reducción del precio correspondiente a toda
                disminución de los costes mencionados en este apartado que se produzcan
                <br />
                entre la fecha de confirmación de reserva de viaje combinado y fecha de inicio del
                mismo. En caso de reducción del precio, la agencia tendrá derecho a deducir los
                gastos administrativos reales del reembolso debido al Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                <em>
                  4. Reducción del precio e indemnización por daños y perjuicios:
                </em>
                <br />
                El Cliente tendrá derecho a una reducción del precio adecuada por cualquier periodo
                durante el cual haya habido falta de conformidad, a menos que la agencia demuestre
                que la falta de conformidad es imputable al viajero. El Cliente tendrá derecho a
                recibir una indemnización adecuada de la agencia por cualquier daño o perjuicio que
                sufra
                <br />
                como consecuencia de cualquier falta de conformidad. La indemnización se abonará sin
                demora indebida. El Cliente no tendrá derecho a una indemnización por daños y
                perjuicios si la agencia demuestra que la falta de conformidad es:
                <br />
                1. imputable al viajero;
                <br />
                2. imputable a un tercero ajeno a la prestación de los servicios contratados e
                imprevisible o inevitable, o
                <br />
                3. debida a circunstancias inevitables y extraordinarias.
                <br />
                En la medida en que los convenios internacionales que vinculan a la Unión Europea
                limiten el alcance o las condiciones del pago de indemnizaciones por parte de
                prestadores de servicios de viaje incluidos en un viaje combinado, las mismas
                limitaciones se aplicarán a los organizadores y minoristas. En los demás casos, el
                contrato podrá limitar la indemnización que debe pagar la agencia siempre que esa
                limitación no se aplique a los daños corporales o perjuicios causados de forma
                intencionada o por negligencia y que su importe no sea inferior al triple del precio
                total del viaje.
                <br />
                Todo derecho a indemnización o reducción del precio en virtud de lo establecido en
                la ley de aplicación no afectará a los derechos de los viajeros contemplados en:
              </p>
              <p class="text-justify text-md text-gray-500">
                1. El Reglamento (CE) n.º 261/2004 del Parlamento Europeo y del Consejo, de 11 de
                febrero de 2004, por el que se establecen normas comunes sobre compensación y
                asistencia a los pasajeros aéreos en caso de denegación de embarque y de cancelación
                o gran retraso de los vuelos, y se deroga el Reglamento (CEE) n.º 295/91.
                <br />
                2. El Reglamento (CE) n. º 1371/2007, del Parlamento Europeo y del Consejo, de 23 de
                octubre de 2007, sobre los derechos y las obligaciones de los viajeros de
                ferrocarril.
                <br />
                3. El Reglamento (CE) n. º 392/2009 del Parlamento Europeo y del Consejo, de 23 de
                abril de 2009, sobre la responsabilidad de los transportistas de pasajeros por mar
                en caso de accidente.
                <br />
                4. El Reglamento (UE) n. º 1177/2010 del Parlamento Europeo y del Consejo, de 24 de
                noviembre de 2010, sobre los derechos de los pasajeros que viajan por mar y por vías
                navegables y por el que se modifica el Reglamento (CE) n. º 2006/2004.
                <br />
                5. El Reglamento (UE) n. º 181/2011 del Parlamento Europeo y del Consejo, de 16 de
                febrero de 2011, sobre los
                <br />
                derechos de los viajeros de autobús y autocar y por el que se modifica el Reglamento
                (CE) n.º 2006/2004.
                <br />
                6. Los convenios internacionales.
                <br />
                El Cliente tendrá derecho a presentar reclamaciones con arreglo a ley aplicable, a
                dichos reglamentos y a los convenios internacionales. * La indemnización o reducción
                del precio concedida en virtud de la ley y la concedida en virtud de dichos
                reglamentos y convenios internacionales, se deducirán la una de la otra para evitar
                el exceso de indemnización.
              </p>
              <p class="text-justify text-md text-gray-500">
                5
                <em>
                  . Forma acordada de pago:
                </em>
                <br />
                Las partes acordarán en el contrato la forma de pago establecida, sea al contado, o
                fraccionado, si bien el importe íntegro deberá estar desembolsado antes de la fecha
                de salida. En caso, de no haberse recibido el precio, se entenderá que el Cliente
                desiste unilateralmente del contrato con los gastos y penalizaciones establecidas en
                el RDL 1/2007.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  4. ASISTENCIA
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                El Cliente podrá enviar mensajes, peticiones o quejas en relación con la ejecución
                del viaje combinado directamente al minorista a través del cual fue adquirido. El
                minorista transmitirá dichos mensajes, peticiones o quejas a la agencia sin demora
                indebida. La agencia deberá proporcionar asistencia adecuada y sin demora indebida
                al Cliente en dificultades, en especial en caso de
                <br />
                circunstancias inevitables y extraordinarias, en particular mediante:
                <br />
                1. el suministro de información adecuada sobre los servicios sanitarios, las
                autoridades locales y la asistencia consular;
                <br />
                2. la asistencia al Cliente para establecer comunicaciones a distancia; y
                <br />
                3. la ayuda para encontrar fórmulas de viaje alternativas.
                <br />
                La agencia podrá facturar un recargo razonable por dicha asistencia si la dificultad
                se ha originado intencionadamente o por negligencia del viajero. Dicho recargo no
                superará en ningún caso los costes reales en los que haya incurrido la agencia. Si
                es imposible garantizar el retorno del viajero según lo convenido en el contrato
                debido a circunstancias inevitables y extraordinarias la agencia asumirá el coste
                del alojamiento que sea necesario, de ser posible de categoría equivalente, por un
                período no superior a tres noches, siendo el coste del exceso de cuenta del Cliente.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  5. MODIFICACIÓN DE OTROS CLAUSULADOS DEL CONTRATO
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                El contrato de viaje combinado, con excepción de lo dispuesto en el punto anterior,
                podrá modificarse de forma unilateral por parte de la agencia o antes del inicio de
                viaje combinado, siempre y cuando el cambio sea insignificante, se disponga dicha
                facultad en el contrato formalizado con el Cliente y se informe al Cliente en
                soporte duradero de dicha alteración en el precio. Ahora bien, en aquellos supuestos
                en la agencia se vea obligada a modificar alguna de las características principales
                del viaje combinado contenidas en las condiciones precontractuales o contractuales o
                proponga al Cliente aumentar el precio del viaje combinado en más de un ocho por
                cien (8%) del total del precio, siempre antes del inicio del viaje combinado, el
                Cliente tendrá la posibilidad de aceptar el cambio propuesto o resolver el contrato
                sin penalización.
                <br />
                En caso de que el viaje combinado sustitutivo al modificado sea de calidad o coste
                inferior, el Cliente tendrá derecho a una reducción en el precio. Las modificaciones
                de clausulados del contrato deben ser comunicadas al Cliente sin demora y haciendo
                alusión a: la repercusión de la modificación en el precio total del viaje combinado;
                el plazo en el que el Cliente debe comunicar su decisión de resolver o aceptar las
                modificaciones y qué ocurre si no comunica nada el Cliente, y en su caso, el viaje
                sustitutivo ofrecido y su precio.
                <br />
                * En el supuesto de que el Cliente solicite cambios voluntarios en su viaje
                combinado, los precios de los servicios turísticos podrán no corresponderse con los
                publicados en el folleto o condiciones precontractuales que dio lugar a la
                contratación.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  6. CESIÓN DEL CONTRATO DE VIAJE COMBINADO
                </strong>
                <br />
                Para que el Cliente tenga la facultad de ceder el contrato de viaje combinado a otra
                persona, la destinataria deberá reunir todas las condiciones aplicables a dicho
                contrato, debiendo ser comunicado previamente a la agencia con una antelación
                razonable de al menos siete (7) días naturales al inicio del viaje combinado. Tanto
                el cedente como el cesionario responderán solidariamente ante el pago de la cuantía
                pendiente de pago, así como de cualquier gasto adicional que haya causado la cesión.
                Para ello será la agencia quién debe proporcionar al cedente la prueba de los gastos
                adicionales.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  7. PAGOS Y REEMBOLSOS
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                El viaje combinado debe estar totalmente pagado para que se le faciliten al Cliente
                todas las prestaciones de su viaje. En el caso de que el cobro sea rechazado por
                cualquier causa, se procederá a la anulación de la reserva, previa comunicación
                informativa para intentar solventar el problema del pago.
                <br />
                En caso de no recibir el pago total del precio pactado en las condiciones, se
                asumirá que el Cliente desiste del viaje siendo de aplicación lo contenido en el
                apartado “2.8. Resolución del contrato de viaje combinado por el Cliente”.
              </p>
              <p class="text-justify text-md text-gray-500">
                En el caso de que la agencia resuelva el contrato de viaje combinado, deberá
                devolver o reembolsar al Cliente las cantidades ya pagadas por el mismo, en un plazo
                no superior a catorce días naturales a contar desde la fecha de finalización del
                viaje combinado.
                <br />
                El Cliente o los viajeros que no se presenten a la hora prevista de salida del viaje
                combinado contratado, no tendrá derecho a la devolución de cantidad alguna abonada,
                salvo que exista acuerdo diferente entre las partes.
                <br />
                En relación con el seguro contra gastos de cancelación contratado por el Cliente, en
                ningún caso la prima abonada por el mismo será reembolsable, por cuanto que el mismo
                despliega sus efectos desde el mismo momento de su celebración, con independencia de
                que el riesgo asegurado ocurra.
                <br />
                * Pago con autorización/Ingreso en cuenta: para el pago de los servicios reservados
                a través de la página web de Findecursocolegio.com y por motivos de seguridad, es
                posible que, en algunos casos, la agencia solicite al cliente una autorización
                específica de cobro (a la que habrá que acompañar la documentación requerida por la
                agencia de viajes).
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  8. RESOLUCIÓN DEL CONTRATO DE VIAJE COMBINADO POR EL CLIENTE
                </strong>
                <br />
                En cualquier momento, pero siempre antes de la fecha de inicio del viaje combinado,
                el Cliente podrá resolver el contrato con una penalización impuesta por la agencia:
                <br />
                1. El contrato de viaje combinado puede establecer una penalización tipo dependiendo
                de los días que resten para la fecha de inicio del viaje combinado a contar desde el
                día en el que se comunique la intención de resolver el contrato, por lo que en
                ningún supuesto podrá ser inferior al 5 por ciento del precio total del viaje
                contratado, si el citado incumplimiento se produce entre los dos meses y quince días
                inmediatamente anteriores a la fecha prevista de realización del viaje; el 10 por
                ciento si se produce entre los quince y tres días anteriores, y el 25 por ciento en
                el supuesto de que el incumplimiento citado se produzca en las 48 horas anteriores.
                <br />
                2. En el caso de que no se disponga de penalización tipo, en el contrato de viaje
                combinado el importe de la penalización equivaldrá al precio del viaje combinado,
                menos el ahorro de costes e ingresos derivados de la utilización alternativa de los
                servicios de viaje.
                <br />
                En cualquier caso, y salvo los supuestos específicos previstos por la ley, serán de
                aplicación los gastos de gestión y suplidos en los que haya incurrido la Agencia
                durante la celebración y ejecución del contrato de viaje combinado. En el caso de
                que los servicios contratados y anulados, que forman parte del viaje combinado,
                estuvieran sujetos a condiciones
                <br />
                económicas especiales de contratación, los gastos de cancelación por desistimiento,
                serán los establecidos por el proveedor de
                <br />
                cada servicio. Cuando como consecuencia de modificaciones en las condiciones del
                contrato de viaje combinado, el Cliente no acepte su
                <br />
                sustitución por otro viaje, la agencia reembolsará al Cliente las cantidades pagadas
                sin aplicar penalizaciones, en el plazo máximo
                <br />
                de catorce días naturales, a contar desde la fecha de resolución del contrato.
                <br />
                Tendrán derecho a resolver el contrato y derecho al reembolso total del precio del
                viaje combinado:
                <br />
                1. aquellos Clientes en cuyos viajes contratados concurran circunstancias
                inevitables y extraordinarias en el lugar de destino, o en las inmediaciones, que
                afecten de forma significativa a la ejecución al viaje o al transporte de pasajeros
                al lugar de destino.
                <br />
                2. si se modifica significativamente alguno de los elementos esenciales del viaje
                combinado que no sea el precio.
                <br />
                3. en el supuesto de que el empresario responsable del viaje combinado, lo cancele
                antes del inicio del viaje, el Cliente tendrá derecho además a recibir una
                compensación.
                <br />
                4. en caso de no ejecución de los servicios cuando ello afecte sustancialmente a la
                ejecución del viaje combinado y la agencia no consiga solucionar el problema.
                <br />
                5. cuando se den circunstancias excepcionales como por ejemplo, graves problemas de
                seguridad que puedan afectar al viaje, el Cliente tampoco tendrán que pagar ninguna
                penalización.
                <br />
                Los Clientes tendrán derecho a una reducción en el precio y/o a una indemnización
                por daños y perjuicios en caso de no ejecución
                <br />
                o ejecución incorrecta de los servicios de viaje.
                <br />
                <span style="text-decoration: underline;">
                  * Derecho de desistimiento:
                </span>
                Los viajes combinados contratados con Findecursocolegio.com carecen del derecho de
                desistimiento al
                <br />
                haber sido contratados por internet.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  9. RESOLUCIÓN DEL CONTRATO POR PARTE DE LA AGENCIA
                </strong>
                <br />
                La agencia puede cancelar el contrato de viaje combinado, indemnizando al Cliente
                por la totalidad de los pagos que el mismo haya realizado, pero sin asumir
                responsabilidad por compensaciones, siempre que se cumplan las condiciones legales
                establecidas en el RDL 1/2007.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  10. RESPONSABILIDAD POR ERRORES EN LA RESERVA
                </strong>
                <br />
                La agencia será responsable de los errores debidos a defectos técnicos que se
                produzcan en el sistema de reservas que le sean atribuibles, así como de los errores
                cometidos durante el proceso de reserva, cuando el empresario haya aceptado
                gestionar la reserva de un viaje combinado.
                <br />
                La agencia no será responsable de los errores de reserva atribuibles al Cliente o
                causados por circunstancias inevitables y extraordinarias.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  11. EJECUCIÓN DEL CONTRATO DE VIAJE COMBINADO
                </strong>
                <br />
                La agencia responderá de forma solidaria frente al Cliente del correcto cumplimiento
                de los servicios de viaje incluidos en el contrato, con independencia de que estos
                servicios los deban ejecutar ellos mismos u otros prestadores. Quien responda ante
                el Cliente tendrá el derecho de repetición frente al empresario al que le sea
                imputable el incumplimiento o cumplimiento defectuoso del contrato en función de su
                respectivo ámbito de gestión del viaje combinado.
                <br />
                Cuando la Agencia abone una compensación, conceda una reducción del precio o cumpla
                las demás obligaciones que impone la ley, podrá solicitar el resarcimiento a
                terceros que hayan contribuido a que se produjera el hecho que dio lugar a la
                compensación, a la reducción del precio o a otras obligaciones.
                <br />
                El Cliente deberá informar a la Agencia sin demora indebida, de cualquier falta de
                conformidad que observe durante la ejecución de un servicio de viaje incluido en el
                contrato.
                <br />
                Si cualquiera de los servicios incluidos en el viaje no se ejecuta de conformidad
                con el contrato la agencia deberá subsanar la falta de conformidad, salvo que
                resulte imposible o si ello entraña un coste desproporcionado, teniendo en cuenta la
                gravedad de la falta de conformidad y el valor de los servicios de viaje afectados.
                En caso de que con arreglo a este apartado no se subsane la falta de conformidad
                será de aplicación la reducción del precio e indemnización de daños y perjuicios.
                Sin perjuicio de las excepciones previstas en el apartado anterior, si la agencia no
                subsana la falta de conformidad en un plazo razonable establecido por el Cliente, el
                propio Cliente podrá hacerlo y solicitar el reembolso de los gastos necesarios. No
                será preciso que el Cliente especifique un plazo límite si la Agencia se niega a
                subsanar la falta de conformidad o si se requiere una solución inmediata.
                <br />
                Cuando una proporción significativa de los servicios de viaje no pueda prestarse
                según lo convenido en el contrato de viaje combinado, la agencia ofrecerá, sin coste
                adicional alguno para el Cliente, fórmulas alternativas adecuadas, de ser posible de
                calidad equivalente o superior a las especificadas en el contrato, para la
                continuación del viaje combinado, también cuando el regreso del Cliente al lugar de
                salida no se efectúe según lo acordado.
                <br />
                Si las fórmulas alternativas propuestas dan lugar a un viaje combinado de menor
                calidad que la especificada en el contrato la agencia aplicará al Cliente una
                reducción adecuada del precio.
                <br />
                El Cliente solo podrá rechazar las fórmulas alternativas propuestas si no son
                comparables a lo acordado en el contrato de viaje combinado o si la reducción del
                precio concedida es inadecuada.
                <br />
                Cuando una falta de conformidad afecte sustancialmente a la ejecución del viaje la
                agencia no la hayan subsanado en un plazo razonable establecido por el Cliente, este
                podrá poner fin al contrato sin pagar ninguna penalización y solicitar, en su caso,
                tanto una reducción del precio como una indemnización por los daños y perjuicios
                causados.
                <br />
                Si el viaje combinado incluye el transporte de la agencia en los casos indicados en
                los dos párrafos anteriores, repatriará además al Cliente en un transporte
                equivalente sin dilaciones indebidas y sin coste adicional.
                <br />
                Si es imposible garantizar el retorno del Cliente según lo convenido en el contrato
                debido a circunstancias inevitables y extraordinarias, la agencia asumirá el coste
                del alojamiento que sea necesario, de ser posible de categoría equivalente, por un
                período no superior a tres noches por viajero. Cuando la normativa europea sobre
                derechos de los pasajeros, aplicable a los correspondientes medios de transporte
                para el regreso del Cliente, establezca períodos más largos, se aplicarán dichos
                períodos.
                <br />
                La limitación de costes a que se refiere el apartado anterior no se aplicará a las
                personas con discapacidad o movilidad reducida, tal como se definen en el artículo
                2.a) del Reglamento (CE) n.º 1107/2006 del Parlamento Europeo y del Consejo, de 5 de
                julio de 2006, sobre los derechos de las personas con discapacidad o movilidad
                reducida en el transporte aéreo, ni a sus acompañantes, mujeres embarazadas y
                menores no acompañados, así como a las personas con necesidad de asistencia médica
                específica, si sus necesidades particulares han sido participadas la agencia al
                menos cuarenta y ocho horas antes del inicio del viaje. La agencia no podrá invocar
                las circunstancias inevitables y extraordinarias a efectos de la limitación de
                responsabilidad, si el transportista no puede acogerse a estas circunstancias en
                virtud de la normativa europea.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  12. PROTECCIÓN DE DATOS PERSONALES
                </strong>
                <br />
                En cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                Personales y garantía de derechos digitales y del Reglamento Europeo 2016/679,
                relativo a la protección de las s personas físicas en lo que respecta al tratamiento
                de datos personales y a la libre circulación de estos datos, la agencia y el Cliente
                se comprometen a respetar y cumplir con la normativa vigente en dicha materia. En
                caso de que efectivamente llegue a contratar con la agencia, la información relativa
                a protección de datos será entregada por la agencia al Cliente en el momento
                dispuesto en la ley. De igual manera puede ver nuestra política de privacidad y
                protección de datos en https://findecursocolegio.com/politica-privacidad.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  13. PRESCRIPCIÓN DE LAS ACCIONES
                </strong>
                <br />
                Prescribirán por el transcurso de dos (2) años las acciones derivadas de los
                derechos reconocidos en la Ley, cómputo que se iniciará el día de celebración del
                contrato.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  14. JURISDICCIÓN APLICABLE
                </strong>
              </p>
              <p class="text-justify text-md text-gray-500">
                Las partes que contraten al amparo de estas condiciones generales de contratación,
                se someten a los Juzgados y Tribunales del domicilio del Cliente o el domicilio del
                empresario, a elección del Cliente, para resolver las discrepancias o reclamaciones
                que susciten la interpretación o la ejecución del contrato de viaje combinado y de
                estas condiciones generales.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  SOLICITUD DE SERVICIOS ESPECIALES
                </strong>
                <br />
                Cada medio de transporte establece sus propias normas y condiciones para el
                tratamiento y prestación de los servicios especiales, no obstante y en todo caso,
                cuando el cliente requiera la prestación de alguno de estos servicios, tales como,
                menores de edad no acompañados, silla de ruedas, mascotas a bordo, equipos
                deportivos, etc. deberá solicitarlo expresamente en el momento de realizar la
                reserva en el campo “Observaciones” cuando se le solicite los datos de pago. La
                agencia de viajes actuará meramente como intermediaria trasladando la solicitud de
                servicio especial a la compañía aérea, pero la prestación definitiva de este tipo de
                servicios está condicionada en todo caso a la previa y expresa confirmación de los
                mismos por parte de la compañía aérea.
                <br />
                La agencia no se responsabiliza de la confirmación o denegación de estos servicios
                por parte de la compañía aérea y tampoco dispone de la información sobre los
                posibles suplementos de precio que pueda requerir la compañía aérea para la
                prestación de estos servicios de carácter especial.
                <br />
                Por ello y para cualquier información que el cliente requiera en relación a este
                tipo de servicios, se le recomienda contactar directamente con la línea aérea
                concreta de que se trate, para su reconfirmación.
              </p>
              <p class="text-justify text-md text-gray-500">
                <strong class="font-bold">
                  PROCESO DE RESERVA
                </strong>
                <br />
                1. Procedimiento de solicitud de reserva
                <br />
                Para reservar a través de nuestra web se deben llevar a cabo los siguientes pasos:
                <br />
                1. Búsqueda según los parámetros deseados por el usuario.
                <br />
                2. Selección de la oferta y, en su caso, de las opciones que en su caso permita la
                oferta, incluyendo número de personas y todo aquello que el programa vaya
                solicitando para conformar la oferta definitiva.
                <br />
                3. Confirmación del presupuesto facilitado por Findecursocolegio.com.
                <br />
                4. Entrega al Cliente de la clave de acceso a su intranet. El Cliente será
                responsable de la custodia y uso de esta clave, declinando
                <br />
                la Agencia cualquier responsabilidad por un uso negligente de esta clave.
                <br />
                5. Aceptación del contrato/condiciones de contratación.
                <br />
                6. Cumplimentación de los datos del centro escolar y los datos de los viajeros.
                <br />
                7. Selección, si así lo desea el Cliente, de la contratación de un seguro de viaje o
                de un seguro de viaje y cancelación, cuyas condiciones generales y particulares se
                facilitarán antes de la contratación del mismo.
                <br />
                En caso de que a lo largo del proceso de reserva y antes de su finalización, el
                usuario detecte que ha cometido algún error en la introducción de datos, deberá
                volver atrás hasta la pantalla correspondiente en la que poder subsanar o bien
                empezar de nuevo el proceso de reserva. Al finalizar la reserva, y para que el
                proceso de compra pueda continuar, el cliente deberá aceptar las condiciones
                generales en el link dispuesto para ello.
                <br />
                2. Pago del precio y confirmación de la reserva
                <br />
                Una vez finalizado el proceso de solicitud de reserva, si la entidad bancaria acepta
                el pago del depósito o, en su caso, de la totalidad del importe del viaje o servicio
                (según proceda y sea debidamente informado al cliente durante el proceso de
                reserva), en la tarjeta suministrada por el cliente, se procederá a remitir la
                confirmación de la reserva a la dirección de correo electrónico facilitada por el
                cliente, dentro de las 24 horas siguientes a la finalización del proceso de
                solicitud de reserva.
                <br />
                En aquellos casos en que el sistema permita el pago fraccionado, la totalidad de la
                reserva deberá ser abonada antes del inicio del viaje o actividad. Encontrará el
                calendario de pagos en su confirmación de la reserva.
                <br />
                La agencia podrá resolver el contrato y aplicar las reglas establecidas para el
                desistimiento antes de la salida si el cliente no realiza cualquiera de los pagos
                previstos en el plazo que la agencia le haya comunicado.
                <br />
                Asimismo, si la entidad bancaria no autoriza el cobro en la tarjeta que el usuario
                utilice como medio de pago, la reserva no podrá ser confirmada.
                <br />
                En aquellos casos en que el sistema permite “Pago vía transferencia Bancaria” y el
                USUARIO así lo indicara, éste tiene la obligación de enviar una copia del
                comprobante de la transferencia Bancaria acreditando dicho pago en un plazo máximo
                indicado en el proceso de compra a: info@findecursocolegio.com
                <br />
                En el caso que no se envíe el comprobante dentro del plazo indicado la reserva
                quedará automáticamente anulada debiendo el Cliente realizar de nuevo la reserva si
                así lo deseara.
                <br />
                Si el Cliente hubiera llegado a realizar la transferencia, pero no hubiera enviado
                el comprobante de pago quedando así anulada la reserva, Findecursocolegio.com se
                compromete a la devolución íntegra del importe abonado.
                <br />
                Findecursocolegio.com no puede garantizar ni el precio ni la disponibilidad de
                plazas de un servicio contratado que ha sido anulado, si con posterioridad a la
                anulación el usuario vuelve a intentar su contratación.
                <br />
                En caso de no indicarse un plazo máximo para enviar el comprobante de la
                transferencia éste debe ser enviado dentro de las 24 horas desde el momento de la
                realización de la reserva.
                <br />
                En el inopinado caso de existir algún error tipográfico o numérico en nuestra web
                que sea manifiesto y evidente para cualquier consumidor medio, Findecursocolegio.com
                actuará con la debida diligencia para subsanarlo. Asimismo, advertirá del error al
                cliente a la mayor brevedad y le reintegrará de manera inmediata cualquier pago que
                haya realizado a Findecursocolegio.com consecuencia del referido error en la
                publicación.
                <br />
                3. Entrega de bonos/títulos transporte
                <br />
                Una vez abonado el precio total del viaje por parte del Cliente,
                Findecursocolegio.com procederá a la emisión de los billetes de transporte a quien
                le será remitido, así como los bonos correspondientes al alojamiento pactado en el
                contrato. Será el Cliente el responsable de entregar los bonos y títulos de
                transporte a los viajeros integrantes del grupo.
              </p>
            </div>
          </div>
          <footer class="flex justify-center mt-4">
            <button
              class="rounded-full bg-transparent border border-neutral-900 hover:bg-neutral-900 hover:text-white py-[0.875rem] px-8 flex gap-2 disabled:opacity-50 disabled:pointer-events-none disabled:border-color-300"
              type="submit"
              disabled={{this.isAcceptTermsDisabled}}
              {{on "click" this.acceptedTerms}}
            >
              <span class="text-uppercase">
                Sí, acepto
              </span>
            </button>
          </footer>
        </div>
      </m.Dialog>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default Route<{
    model: {
        id: string;
        activeTab: string;
    };
}>(template(`
    <section class="w-full px-8 py-8">
      <Tab @tripId={{@model.id}} @activeTab={{@model.activeTab}} />
    </section>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
